import React, {useMemo, useState} from "react";
import {SwippableModal} from "@Components/SwippableModal";
import {useUser} from "@Hooks/firebase";
import './AgencySelector.scss';
import {RadioButton} from "primereact/radiobutton";
import {Agency} from "@Types/Agency";
import {useApi} from "@Hooks/api";

export type AgencySelectorProps = Record<string, never>
export const AgencySelector: React.FC<AgencySelectorProps> = () => {

    const user = useUser(true);
    const [visible, setVisible] = useState<boolean>(false);
    const Api = useApi();

    const agencies = useMemo(() => {
        return user.meta.agencies;
    }, [user]);

    const onAgencyChange = async (agency: Agency) => {
        await Api.user_call_changeUserAgency(agency);
        await user.loadUser();
        setVisible(false);
    }

    return (
        <div className="AgencySelector flex align-items-center" onClick={() => setVisible(true)}>
            <i className="pi pi-angle-down mr-2"/>
            <div className="AgencySelector-name">
                {user.meta.selectedAgency.name}
            </div>
            <SwippableModal visible={visible} onHide={() => {
                setVisible(false);
            }}>
                <div  className="AgencySelector-modal h-auto">
                    <div className="AgencySelector-modal-title">Sélectionner une agence</div>
                    {agencies.map(agency => {
                        return <div key={agency.agencyUid}
                                    className="h-auto AgencySelector-modal-line flex align-items-center"
                                    onClick={() => onAgencyChange(agency)}
                        >
                            <RadioButton
                                inputId={agency.agencyUid}
                                name="agency"
                                value={agency.agencyUid}
                                checked={user.meta.selectedAgency.agencyUid === agency.agencyUid}
                                style={{marginRight: "12px", alignSelf: 'self-start'}}
                            />
                            <div className="AgencySelector-modal-line-label">{agency.name}</div>
                        </div>
                    })}
                </div>
            </SwippableModal>
        </div>
    )
}
