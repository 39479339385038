import React, {useContext} from "react";
import logo from "@Assets/images/logo.svg";
import styled from "styled-components";
import {PageHeaderContext} from "../Contexts/PageHeaderProvider";
import {useNavigate} from "react-router-dom";
import {useNetworkState} from "react-use";
import './PageHeader.scss';
import {Badge} from "primereact/badge";
import {classNames} from "primereact/utils";
import {useUser} from "@Hooks/firebase";

const IconHolder = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;

  i {
    font-size: 20px;
  }
`
export type PageHeaderProps = Record<string, never>
export const PageHeader: React.FC<PageHeaderProps> = () => {

    const navigate = useNavigate();

    const {header} = useContext(PageHeaderContext);
	const user = useUser();
    const network = useNetworkState();

    return (
        <>
            <div className="PageHeader flex justify-content-between align-items-center bg-white w-full"
                 style={{padding: "16px 24px"}}>
                <IconHolder>
                    {header.left && <i onClick={header.left.action} className={classNames([header.left.icon, header.left.badge && "p-overlay-badge"])}>
                        {header.left.badge && <Badge value={header.left.badge} size="normal"/>}
                    </i>}
                </IconHolder>
                <img src={logo} alt={"france pneus sélection"} onClick={() => {
                    user && user.firebase ? navigate('/home') : navigate('/public');
                }}/>
                <IconHolder>
                    {header.right && <i onClick={header.right.action} className={classNames([header.right.icon, header.right.badge && "p-overlay-badge"])}>
                        {header.right.badge && <Badge value={header.right.badge} size="normal"/>}
                    </i>}
                </IconHolder>
            </div>
            {!network.online && <div className="Offline flex">
                <i className="pi pi-info-circle mr-2"/>
                <div className="Offline-text">
                    Vous êtes hors ligne
                </div>
                <div className="ml-auto Offline-link">
                    En savoir plus
                </div>
            </div>}
        </>
    )
}
