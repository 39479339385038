import React, {useContext, useEffect, useMemo, useState} from "react";
import {OrderContext} from "@Contexts/OrderContext";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Order} from "@Types/Order";
import { LoadingDiv } from "@Components/LoadingDiv";
import {CatalogItem} from "@Components/CatalogItem";
import {numberWithSpaces} from "@Utils/price.utils";
import './OrderDetails.scss';

export type OrderDetailsProps = Record<string, never>;
export const OrderDetails: React.FC<OrderDetailsProps> = () => {

    const {orders, loadOrders, isLoading} = useContext(OrderContext);
    const location = useLocation();
    const {orderUid} = useParams<{orderUid?: string}>();

    const [order, setOrder] = useState<Order | null>(null );

    const navigate = useNavigate();

    useEffect(() => {
        if (location.state) {
            setOrder(location.state as Order);
        } else {
            loadOrders().then(() => {
                setOrder(orders.find(o => o.orderUid === orderUid) || null)
            });
        }
    }, [location,orderUid]);


    const total = useMemo(() => {
        if (!order) return 0;

        return order.items.reduce((acc, cur) => acc + cur.quantity * cur.product.price, 0)
    }, [order])

    return order &&
        <LoadingDiv loading={isLoading} className="OrderDetails">
            <div className="OrderDetails-title">
                MA COMMANDE N°{order!.orderUid.substring(0,6)} ({order!.items.length})
            </div>
            <div className="OrderDetails-products mt-3">
                {order!.items.map(item => <CatalogItem showAvailability={false} key={item.product.reference} product={item.product} quantity={item.quantity} onClick={() => {navigate(`/home/${item.product.reference}`, {
                    state: item.product
                })}}/>)}
            </div>
            <div className="fps--card-variant w-full flex flex-column justify-content-center align-items-center fixed left-0 bottom-0 p-4" style={{background:"white"}}>
                <div className="flex justify-content-between align-items-center w-full mb-3">
                    <div className="fps__text--small">Total HT :</div>
                    <div className="fps__text--medium-bold">{numberWithSpaces(total)} €</div>
                </div>
                <div className="flex justify-content-between align-items-center w-full mb-3">
                    <div className="fps__text--small">Total TTC :</div>
                    <div className="fps__text--medium-bold">{numberWithSpaces(total * 1.2)} €</div>
                </div>
            </div>
        </LoadingDiv>

}
