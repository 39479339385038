import React, {PropsWithChildren, useCallback} from "react";
import ReactDOM from "react-dom";
import "./SwippableModal.scss";
import {AnimatePresence, motion, useMotionValue} from "framer-motion";

export type SwippableModalProps = {
    visible: boolean;
    onHide: () => void;
}
export const SwippableModal: React.FC<PropsWithChildren<SwippableModalProps>> = props => {

    const y = useMotionValue(0);

    const onDragEnd = useCallback(() => {
        if (y.get() > 80) {
            props.onHide();
        }
    }, [y]);


    return ReactDOM.createPortal(
        props.visible &&
        <motion.div className="SwippableModal-mask flex flex-column">
            <div onClick={e => {
                e.stopPropagation();
                props.onHide();
            }} className="flex-grow-1">
            </div>
            <AnimatePresence>
                <motion.div
                    drag="y"
                    className="SwippableModal-wrapper"
                    style={{y, maxHeight: "95vh"}}
                    dragConstraints={{top: 0, bottom: 80}}
                    dragElastic={{top: 0.05, bottom: 0.3}}
                    onDragEnd={onDragEnd}
                    dragSnapToOrigin
                    initial={{marginBottom: "-100%"}}
                    animate={{marginBottom: "-5vh"}}
                    exit={{marginBottom: "-100%"}}
                    transition={{
                        marginBottom: {duration: 0.3},
                        default: {ease: 'easeInOut'}
                    }}
                >
                    <div className="SwippableModal-wrapper-handle flex justify-content-center align-items-center">
                    </div>
                    <div className="SwippableModal-wrapper-content" style={{padding: "24px", overflow: "auto", maxHeight: "calc(95vh - 64px)"}}>
                        {props.children}
                    </div>
                </motion.div>
            </AnimatePresence>
        </motion.div>,
        document.getElementById('root')!
    );
}
