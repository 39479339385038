import {QuotationItem} from "@Types/Quotation";

export function numberWithSpaces(x: number) {
    const parts = x.toFixed(2).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(",");
}

export const calculateMarginFromPercent = (price: number, margin: number) => {
	return price / (1 - margin);
}

export const calculateMarginFromPrice = (price: number, margin: number) => {
	return 1 - price / (price + margin);
}



export const calculateItemMargin = (item: QuotationItem): number => {
	return  calculateMarginFromPercent(item.product.price, item.margin / 100);
}

export const calculateTotalWithMargin = (item: QuotationItem[], startAt: number = 0): number => {
	return item.reduce((a,c) => a + calculateItemMargin(c) * c.quantity, startAt);
}
