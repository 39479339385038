import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Button} from "primereact/button";
import {CartContext} from "@Contexts/CartProvider";
import './OrderValidate.scss';
import {CustomEventDialog} from "@Components/CustomEventDialog";
import {InputText} from "primereact/inputtext";
import {useUser} from "@Hooks/firebase";
import {Checkbox} from "primereact/checkbox";
import {Dropdown} from "primereact/dropdown";
import {useFormik} from "formik";
import type {CartItem, Order as OrderI} from "@Types/Order";
import {OrderShippingSchema} from "@Schemas/order.schema";
import {classNames} from "primereact/utils";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {useNavigate} from "react-router-dom";
import {HiddableDiv} from "@Components/HiddableDiv";
import {OrderContext} from "@Contexts/OrderContext";
import {numberWithSpaces} from "@Utils/price.utils";

export type OrderProps = Record<string, never>
export const OrderValidate: React.FC<OrderProps> = () => {

    const {total} = useContext(CartContext);

	const totalStr = (items: CartItem[]) => {
		return items.reduce((a,c) => a + c.quantity * c.product.price , 0);
	};

    const [visibleWarning, setVisibleWarning] = useState<boolean>(false);

    const {meta} = useUser(true);
    const {cart, resetCart} = useContext(CartContext);
    const {tmpOrder, setTmpOrder} = useContext(OrderContext);
    const Api = useApi();

    const initialValues: OrderI['shipping_details'] = useMemo(() => {
		if (tmpOrder) {
			return tmpOrder.shipping_details
		} else {
			return {
				name: '',
				pickup: false,
				location: '',
				reference: '',
				phone: ''
			}
		}
    }, [tmpOrder]);

    const {success, error} = useToast();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async (values: OrderI['shipping_details']) => {
        setLoading(true);
        await Api.order_call_createOrder({
            order: {
                shipping_details: values,
                items: tmpOrder?.items || cart,
                status: false,
                orderUid: '',
                createdAt: new Date().toISOString(),
                archived: false,
                client: meta
            }
        }).then(res => {
            if (res.result === "ok") {
                success('Votre commande a été envoyée !');
				if (tmpOrder) {
					Api.quotation_call_deleteQuotation({quotationUid: tmpOrder.quotationUid}).then(res => {
						if (res.result === 'ok') {
							setTmpOrder(undefined);
						}
					})
				} else {
					resetCart();
				}
                navigate('/home/catalog');
            } else {
                error('Une erreur est survenue lors de votre commande');
            }
        }).catch(err => {
            error(err.message);
        }).finally(() => {
            setLoading(false);
        });
    }

    const orderFormik = useFormik({
        initialValues,
        validationSchema: OrderShippingSchema,
	    enableReinitialize: true,
        onSubmit,
        validateOnMount: true
    });

    const handleChange = (name: string, value: any) => {
        orderFormik.setFieldValue(name, value, true);
        console.log(name, value);
    }

    const [inputFocused, setInputFocused] = useState<boolean>(false);


    const onInputFocus = () => {
        setInputFocused(true);
    };

    const onInputBlur = () => {
        setInputFocused(false);
    }

    const options = useMemo(() => {
        return [
            {
                label: 'Agences',
                items: meta.agencies.map(addr => ({
                    label: addr.name,
                    location: addr.address
                }))
            },
            {
                label: 'Mes adresses',
                items: meta.custom_addresses
            }
        ]
    }, [meta])

    const onOrder = useCallback(() => {
        if (total <= 500 && !orderFormik.values.pickup)  {
            setVisibleWarning(true);
        } else {
            void orderFormik.submitForm()
        }
    }, [total, orderFormik])

    return (
        <div className="Order">
            <CustomEventDialog title="Attention" showIcon={false} text="Votre commande est inférieure à 500€, des frais de livraison seront appliqué à votre commande." visible={visibleWarning} setVisible={setVisibleWarning} actions={[{
                label: 'VALIDER MA COMMANDE',
                action: () => orderFormik.submitForm()
            }, {
                label: 'ANNULER',
                action: () => setVisibleWarning(false),
                classNames: ['fps__button--secondary-no-border']
            }]} />
            <div className="Order-title">
                MA COMMANDE
            </div>
            <div className="Order-form mt-4">
                <div className="Order-form-title">
                    Informations professionnelles
                </div>
                <div className="Order-form-input mt-3">
                    <label className="mb-2" htmlFor="name">Nom de l'exploitant</label>
                    <InputText
                        placeholder="Saisir un nom"
                        name="name"
                        id="name"
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        value={orderFormik.values.name}
                        onChange={(e) => handleChange('name', e.currentTarget.value)}
                    />
                </div>
                <div className="Order-form-input mt-3">
                    <label className="mb-2" htmlFor="phone">Numéro de téléphone</label>
                    <InputText
                        placeholder="Saisir un numéro de téléphone"
                        id="phone"
                        name="phone"
                        value={orderFormik.values.phone}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        onChange={(e) => handleChange('phone', e.currentTarget.value)}
                    />
                </div>
                <div className="Order-form-input my-3">
                    <label className="mb-2" htmlFor="reference">Référence de commande</label>
                    <InputText
                        placeholder="Saisir une référence"
                        id="reference"
                        name="reference"
                        value={orderFormik.values.reference}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        onChange={(e) => handleChange('reference', e.currentTarget.value)}
                    />
                </div>
                <div className="Order-form-warning flex">
                    <i className="pi pi-info-circle mr-2"/>
                    <div>
                        Agence: {meta.selectedAgency.name}
                    </div>
                </div>
                <hr className="my-5"/>
                <div className={classNames([orderFormik.values.pickup && 'Order-form-disabled'])}>
                    <div className="Order-form-dropdown my-4">
                        <label className="block mb-2">Adresse de livraison</label>
                        <div className="relative">
                            <i className="pi pi-map absolute z-1"/>
                            <Dropdown
                                disabled={orderFormik.values.pickup}
                                appendTo="self"
                                options={options}
                                editable
                                optionGroupLabel='label'
                                optionLabel="label"
                                optionValue="location"
                                optionGroupChildren="items"
                                placeholder="Saisir une adresse"
                                onChange={e => handleChange('location', e.value)}
                                className="pl-5"
                                id="location"
                                name="location"
                                value={orderFormik.values.location}
                                emptyMessage="Aucune adresse enregistrée"
                            />
                        </div>
                    </div>
                    <div className="Order-form-check flex align-items-center">
                        <Checkbox checked={orderFormik.values.pickup} onChange={e => handleChange('pickup', e.checked)}/>
                        <label className="block" style={{marginLeft: 12}} htmlFor="pickup"> Enlèvement de la commande</label>
                    </div>
                    <div className="Order-form-warning mt-3">
                        <i className="pi pi-info-circle mr-2"/>
                        <div>
                            Saisir une nouvelle adresse ou séléctionnez une adresse parmis vos favorites.
                        </div>
                    </div>
                </div>
            </div>
            <HiddableDiv hidden={inputFocused} className="z-2 fps--card-variant w-full flex flex-column justify-content-center align-items-center fixed left-0 bottom-0 p-4" style={{background:"white"}}>
                <div className="flex justify-content-between align-items-center w-full mb-3">
                    <div className="fps__text--small">Total HT :</div>
                    <div className="fps__text--medium-bold">{numberWithSpaces(totalStr(tmpOrder?.items || cart))} €</div>
                </div>
                <div className="flex justify-content-between align-items-center w-full mb-3">
                    <div className="fps__text--small">Total TTC :</div>
                    <div className="fps__text--medium-bold">{numberWithSpaces(totalStr(tmpOrder?.items || cart) * 1.2)} €</div>
                </div>
                <Button disabled={!orderFormik.isValid || loading} loading={loading} label="VALIDER MA COMMANDE" onClick={onOrder} className="w-full"/>
                <Button disabled={loading} label="RETOUR AU PANIER" onClick={() => navigate('/home/cart')} className="w-full fps__button--secondary-no-border pb-0"/>
            </HiddableDiv>
        </div>
    )
}
