import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo } from "react";
import { Product } from "@Types/Product";
import { useUser } from "@Hooks/firebase";
import { CatalogContext } from "./CatalogContext";
import { CartItem } from "@Types/Order";
import produce from "immer";
import { useStore } from "@nanostores/react";
import { addItem, CartStore, removeItem, updateItem, updateUserCart } from "../stores/cart";
import {numberWithSpaces} from "@Utils/price.utils";

export interface ICartContext {
    cart: CartItem[];
    setCart: (cart: CartItem[]) => void;
    addItem: (product: Product, quantity: number) => void;
    updateItem: (product: Product, quantity: number) => void;
    deleteItem: (product: Product) => void;
    resetCart:() => void;
    total: number;
    totalStr: (num: number) => string;
    checkCart: () =>void;
}

export const CartContext = React.createContext<ICartContext>({
    cart: [],
    total: 0,
    resetCart(){return;},
    totalStr: (a: number) => a.toFixed(2),
    addItem(){return;},
    deleteItem(){return;},
    setCart(){return;},
    updateItem(){return;},
    checkCart(){return;}
});

export const CartProvider: React.FC<PropsWithChildren> = props => {

    const user = useUser(true);
    const { products } = useContext(CatalogContext);

    const cart = useStore(CartStore);

    const updateCart = useCallback(() => {
        if (user) {
            console.log("checking cart");

            const userCart = cart[user.meta.userUid]|| [];

            const changes = userCart.filter((item: CartItem) => {
                const product = products.find(p => p.reference === item.product.reference);
                if (!product) return false;
                return product.quantity != item.quantity;
            });

            if (changes.length > 0) {
                updateUserCart(user.meta,
                    (cart[user.meta.userUid] || [])
                    .filter(item => {
                        const product = products.find(p => p.reference === item.product.reference);
                        return Boolean(product);
                    })
                    .map((item: CartItem) => {

                        const product = products.find(p => p.reference === item.product.reference)!;
                        return {
                            product,
                            quantity: item.product.quantity < 10 ? Math.min(item.quantity, item.product.quantity) : item.quantity
                        }
                    }))
            }
        }
    }, [products, cart, user.meta]);

    const addItemToCart = useCallback((product: Product, quantity: number) => {
        if (user) {

            const userCart = cart[user.meta.userUid] || [];


            const foundProductIndex = userCart.findIndex((p: CartItem) => p.product.reference === product.reference);

            if (foundProductIndex === -1) {
                addItem(user.meta, {
                    quantity: product.quantity < 10 ? Math.min(quantity, product.quantity) : quantity,
                    product
                });
            } else if (userCart) {
                updateItem(user.meta, produce(userCart[foundProductIndex], draft => {
                    draft.quantity = product.quantity < 10 ? Math.min(draft.quantity + quantity, product.quantity) : quantity + draft.quantity;
                    return draft;
                }))
            }
        }
    }, [cart, user.meta])

    const updateItemInCart = useCallback((product: Product, quantity: number) => {
        if (user)
            updateItem(user.meta, { product, quantity });
    }, [cart, user.meta])

    const removeItemInCart = useCallback((product: Product) => {
        if (user)
            removeItem(user.meta, product);
    }, [cart, user.meta])

    const userCart = useMemo(() => {
        if (user.meta.userUid) {
            return cart[user.meta.userUid] || [];
        } else {
            return [];
        }
    }, [cart, user.meta])

    useEffect(() => {
        updateCart()
    }, [updateCart]);


    const total = useMemo(() => {
        return userCart.reduce((a,c) => a + c.quantity * c.product.price, 0);
    }, [userCart])

    const resetCart = useCallback(() => {
        if (user.meta)
            updateUserCart(user.meta, []);
    }, [user])

    return (
        <CartContext.Provider value={{
            updateItem: updateItemInCart,
            setCart: updateCart,
            deleteItem: removeItemInCart,
            addItem: addItemToCart,
            cart: userCart,
            total,
            resetCart,
            totalStr: numberWithSpaces,
            checkCart: updateCart,
        }}>
            {props.children}
        </CartContext.Provider>
    )
}
