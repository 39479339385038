import * as yup from "yup";

export const QuotationClientSchema = yup.object().shape({
    name: yup.string().required('Le nom du client est obligatoire !'),
    phone: yup.string().required('Le numéro de téléphone est obligatoire'),
    mail: yup.string().email('Adresse e-mail incorrecte').required("Le champ email est obligatoire")
})

export const QuotationSchema = yup.object().shape({
    client: QuotationClientSchema,
    reminder_delay: yup.number().notRequired().nullable()
})
