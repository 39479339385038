import React, {useCallback} from "react";
import {useFormik} from "formik";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import './SignupForm.scss';
import {useAuth} from "@Hooks/firebase";
import {useToast} from "@Hooks/toast";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import signupSchema from "@Schemas/signup.schema";
import {useApi} from "@Hooks/api";

const initialValues = {
    password: "",
    confirmPassword: ""
};

export type SignupFormProps = Record<string, never>
export const SignupForm: React.FC<SignupFormProps> = () => {

    const navigate = useNavigate();

    const auth = useAuth();

    const toast = useToast();

    const {code} = useParams<{code?: string}>();

    const [searchParams] = useSearchParams();

    const Api = useApi();

    const handleSubmit = useCallback((values: typeof initialValues) => {
        if (auth && code) {
            Api.user_call_confirmUser({
                password: values.password,
                code
            }).then(() => {
                toast.success("Vous êtes correctement inscrit ! Pour accéder à l'application veuillez vous connecter en utilisant vos identifiants.");
                navigate('/login')
            })
        }

    }, [auth]);

    const formik = useFormik({
        initialValues,
        validationSchema: signupSchema,
        onSubmit: handleSubmit
    });

    return (
        <div className="SignupForm flex flex-column justify-content-center px-4" style={{marginTop: "162px"}}>
            <div className="fps__text--big mb-5">
                INSCRIPTION
            </div>
            <div className="flex align-items-center w-full p-input-icon-left mb-3">
                <i className="pi pi-lock fps__text--placeholder-icon" />
                <InputText
                    disabled
                    id="email"
                    name="email"
                    className="w-full"
                    placeholder={searchParams.get('email') || ''}
                    />
            </div>
            <div className="flex align-items-center w-full p-input-icon-left mb-3">
                <i className="pi pi-lock fps__text--placeholder-icon" />
                <InputText
                    id="password"
                    name="password"
                    type="password"
                    className="w-full"
                    placeholder="Saisir mot de passe"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                />
            </div>
            <div className="flex align-items-center w-full p-input-icon-left mb-3">
                <i className="pi pi-lock fps__text--placeholder-icon" />
                <InputText
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    className="w-full"
                    placeholder="Confirmer mot de passe"
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                />
            </div>
	        <div className="fps__text--small-gray text-left mb-3">
		        En confirmant votre inscription, vous confirmez avoir pris connaissance de nos <a className="fps__text--link" href="/assets/CGU.pdf" download>CGUs</a>
			</div>
            <Button label="CONFIRMER INSCRIPTION" className="fps__button--normal mb-3" onClick={formik.submitForm}/>
            <div>
                {Object.entries(formik.errors).map(([k,v]) => formik.touched[k as keyof typeof formik.errors] && (
                    <div className="fps__text--small-lightred">
                        {v}
                    </div>
                ))}
            </div>
        </div>
    )
}
