import React, {useCallback, useState} from "react";
import './MyAddresses.scss';
import {Button} from "primereact/button";
import {SwippableModal} from "@Components/SwippableModal";
import {InputText} from "primereact/inputtext";
import {useUser} from "@Hooks/firebase";
import {CustomAddress} from "@Types/User";
import {usePromise} from "@Hooks/promise";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import produce from "immer";
export type MyAddressesProps = Record<string, never>
export const MyAddresses: React.FC<MyAddressesProps> = () => {

    const {meta, loadUser} = useUser(true);

    const [visible, setVisible] = useState<boolean>(false);
    const [selectedAddress, setSelectedAddress] = useState<CustomAddress>();

    const onShowAdd = () => {
        setSelectedAddress(undefined);
        resetForm();
        setVisible(true);
    }

    const [label, setLabel] = useState<string>('');
    const [location, setLocation] = useState<string>('');

    const resetForm = () => {
        setLabel('');
        setLocation('');
    }

    const onEditAddress = (address: CustomAddress) => {
        setVisible(true);
        setSelectedAddress(address);
        setLocation(address.location);
        setLabel(address.label);
    }

    const Api = useApi();

    const {success} = useToast();

    const saveUser = useCallback((data: CustomAddress[]) => {
        return Api.user_call_updateClient({
            ...meta,
            custom_addresses: data
        }).then(res => {
            if (res.result === "ok") {
                return loadUser();
            }
        }).finally(() => {
            resetForm();
            setSelectedAddress(undefined);
            setVisible(false);
        })
    }, [meta])

    const createAddress = useCallback(() => {
        return produce(meta.custom_addresses, (arr) => {
            arr.push({
                label,
                location
            })
            return arr;
        })
    }, [label, location]);

    const editAddress = useCallback(() => {
        return produce(meta.custom_addresses, (arr) => {
            if (selectedAddress) {
                const index = arr.findIndex(e => e.label === selectedAddress.label)
                if (index !== -1)
                    arr[index] = {
                        label,
                        location
                    }
            }
            return arr;
        })
    }, [label, location, selectedAddress]);

    const deleteAddress = useCallback((address?: CustomAddress) => {
        return produce(meta.custom_addresses, (arr) => {
            if (address) {
                const index = arr.findIndex(e => e.label === address.label)
                if (index !== -1)
                    arr.splice(index, 1);
            }
            return arr;
        })
    }, [label, location, selectedAddress]);

    const [changeAddress, changeAddressMap] = usePromise(async (op: 'delete' | 'create' | 'update', address?: CustomAddress) => {
        switch (op) {
            case 'create':
                return saveUser(createAddress()).then(() => {
                    success('Addresse enregistrée');
                });
            case 'update':
                return saveUser(editAddress()).then(() => {
                    success('Addresse modifiée');
                });
            case 'delete':
                return saveUser(deleteAddress(address)).then(() => {
                    success('Addresse supprimée');
                });
        }
    });

    return (
        <div className="MyAddresses">
            <div className={'MyAddresses-subtitle mb-4'}>
                Mes adresses favorites
            </div>
            {meta.custom_addresses.map(e => {
                return <div key={e.label} className="MyAddresses-item mb-2 flex justify-content-between">
                    <div>
                        <div className="MyAddresses-item-name">{e.label}</div>
                        <div className="MyAddresses-item-address mt-2">{e.location}</div>
                    </div>
                    <div>
                        <Button disabled={changeAddressMap.get('delete')} icon={"pi pi-pencil"} onClick={() => onEditAddress(e)} className="fps__button--secondary-no-border p-button-rounded"/>
                        <Button loading={changeAddressMap.get('delete')} icon={"pi pi-trash"} className="p-button-rounded ml-3" onClick={() => changeAddress('delete')('delete', e)}/>
                    </div>
                </div>
            })}
            <div className="fixed left-0 bottom-0 px-5 py-4 bg-white w-full">
                <Button label="Ajouter une adresse" icon="pi pi-plus" onClick={onShowAdd} className="w-full"/>
            </div>
            <SwippableModal visible={visible} onHide={() => setVisible(false)}>
                <div className="fps__text--big">
                    {selectedAddress ? "Modifier l'adresse" : "Ajouter une adresse"}
                </div>
                <div className="mt-4" style={{marginBottom: 50}}>
                    <InputText placeholder={"Saisir un nom"} className={"w-full"} value={label} onChange={e => setLabel(e.target.value)}/>
                    <span className="p-input-icon-left w-full mt-3">
                        <i className="pi pi-map" style={{color: "#98A2B3"}}/>
                        <InputText placeholder={"Saisir l'adresse"} className={"w-full"} value={location} onChange={e => setLocation(e.target.value)}/>
                    </span>
                </div>
                <div>
                    <Button
                        loading={changeAddressMap.get(selectedAddress ? 'edit' : 'create')}
                        label={selectedAddress ? "Modifier l'adresse" : "Ajouter l'adresse"}
                        className="w-full"
                        onClick={() => changeAddress(selectedAddress ? 'edit' : 'create')(selectedAddress ? 'update' : 'create')}
                    />
                </div>
            </SwippableModal>
        </div>
    )
}
