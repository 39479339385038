import React from "react";
import {classNames} from "primereact/utils";

export type HiddableDivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    hidden: boolean;
};
export const HiddableDiv: React.FC<HiddableDivProps> = props => {
    return (
        <div {...props} className={classNames([props.className?.replace('flex', props.hidden ? '' : 'flex'), props.hidden && 'hidden'])} />
    )
}
