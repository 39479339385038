import {Product} from "@Types/Product";
import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {CatalogContext} from "@Contexts/CatalogContext";
import {LoadingDiv} from "@Components/LoadingDiv";
import {Button} from "primereact/button";
import {Badge} from "primereact/badge";
import {InputNumber} from "primereact/inputnumber";
import {classNames} from "primereact/utils";
import {CartContext} from "@Contexts/CartProvider";
import {useApi} from "@Hooks/api";
import {useUser} from "@Hooks/firebase";
import {CustomEventDialog} from "@Components/CustomEventDialog";
import './ProductDetails.scss';
import {ProductTechnicalInformations} from "@Components/ProductTechnicalInformations";

export type ProductDetailsProps = {
	edit?: boolean;
}
export const ProductDetails: React.FC<ProductDetailsProps> = props => {

	const {cart, addItem, updateItem, deleteItem} = useContext(CartContext);
	const {products, loadProducts, isLoading} = useContext(CatalogContext);

	const location = useLocation();
	const navigate = useNavigate();
	const {reference} = useParams<{ reference?: string }>();

	const Api = useApi();
	const user = useUser(true);

	const [quantity, setQuantity] = useState<number | null>(1);
	const [product, setProduct] = useState<Product | undefined>(location.state as Product | undefined);
	const [visible, setVisible] = useState<boolean>(false);
	const [requestVisible, setRequestVisible] = useState<boolean>(false);
	const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

	const [requestInformationLoading, setRequestInformationLoading] = useState<boolean>(false);

	useEffect(() => {
		if (location.state) {
			setProduct(location.state as Product)
		} else {
			loadProducts();
		}
	}, [location]);

	useEffect(() => {
		if (!location.state && products.length > 0 && reference) {
			setProduct(products.find(p => p.reference === reference));
		}
	}, [products, location]);

	useEffect(() => {
		const found = cart.find(it => it.product.reference === reference);
		if (props.edit && found) {
			setQuantity(found.quantity)
		}
	}, [props.edit]);

	function onAddToCart() {
		if (product && quantity) {
			addItem(product, quantity);
			setVisible(true);
		}
	}

	function onContinue() {
		setVisible(false);
		if (!props.edit)
			navigate('/home')
		else
			navigate('/home/cart')
	}

	function onGoToCart() {
		setVisible(false);
		navigate('/home/cart')
	}

	function onUpdateItem() {
		if (product && quantity) {
			updateItem(product, quantity);
			setVisible(true);
		}
	}

	function onDeleteItem() {
		if (product) {
			deleteItem(product);
			setDeleteVisible(true);
		}
	}

	async function onRequest() {
		setRequestInformationLoading(true);
		try {
			if (product) {
				await Api.request_call_createRequest({
					product: product,
					agency: user.meta.selectedAgency,
					status: false,
					client: user.meta,
					requestUid: '',
					createdAt: ''
				});
				setRequestVisible(true);
			}
		} finally {
			setRequestInformationLoading(false);
		}
	}

	return (
		<LoadingDiv loading={isLoading} className="ProductDetails relative">
			{
				!props.edit ?
					<CustomEventDialog title="AJOUTÉ AU PANIER" visible={visible} setVisible={setVisible} actions={[
						{label: 'CONTINUER MES ACHATS', action: onContinue,},
						{label: 'VOIR MON PANIER', action: onGoToCart, classNames: ['fps__button--secondary mt-3']}
					]}/>
					:
					<CustomEventDialog title="PANIER MIS À JOUR" visible={visible} setVisible={setVisible} actions={[
						{label: 'RETOUR', action: onContinue,}
					]}/>
			}
			<CustomEventDialog title="NOUS VOUS CONTACTONS RAPIDEMENT" visible={requestVisible}
			                   setVisible={setRequestVisible} actions={[
				{label: 'CONTINUER MES ACHATS', action: () => navigate('/home')}
			]}/>
			<CustomEventDialog title="ARTICLE RETIRÉ DU PANIER" visible={deleteVisible} setVisible={setDeleteVisible}
			                   actions={[
				                   {label: 'RETOUR', action: () => navigate('/home/cart')}
			                   ]}/>
			{product ? <>
				<ProductTechnicalInformations product={product} contentStyle={{paddingBottom: '270px'}}/>
				<div className="ProductDetails-footer fixed bottom-0 w-full">
					<div className="ProductDetails-footer-availability flex justify-content-between mb-3">
						<div className="flex justify-content-start align-items-center">
							<div className="flex justify-content-center align-items-center mr-1">
								{product.quantity <= 0 ? <Badge severity="danger"/> : (product.quantity <= 10 ?
									<Badge severity="warning"/> : <Badge severity="success"/>)}
							</div>
							<div>
								{product.quantity <= 0 ? "Hors stock" : (product.quantity <= 10 ? `Stock limité (${product.quantity})` : "Disponible")}
							</div>
						</div>
						<div className="fps__text--medium">
							{product.price.toFixed(2)} € UHT
						</div>
					</div>
					<div className="ProductDetails-footer-actions flex flex-column">
						{
							product.quantity > 0 ?
								<>
									<div className="flex justify-content-start">
										<InputNumber
											value={quantity}
											onChange={e => {
												console.log(e.originalEvent.type)
												if (e.originalEvent.type ===  "touchstart") return;

												setQuantity(e.value)
											}}
											//onValueChange={e => setQuantity(e.value)}
											showButtons
											buttonLayout="horizontal"
											incrementButtonIcon="pi pi-plus"
											decrementButtonIcon="pi pi-minus"
											min={1}
											max={product.quantity <= 10 ? product.quantity : undefined}
											decrementButtonClassName={classNames([quantity === 1 && "p-inputnumber-button-disabled"])}
											incrementButtonClassName={classNames([product.quantity <= 10 && quantity === product.quantity && "p-inputnumber-button-disabled"])}
										/>
										{
											props.edit ?
												<>
													<Button label="Mettre à jour" className="ml-auto uppercase mr-2"
													        onClick={onUpdateItem}/>
													<Button icon="pi pi-trash"
													        className="fps__button--secondary-no-border"
													        onClick={onDeleteItem}/>
												</>
												: <>
													<Button label="Ajouter au panier" className="ml-auto uppercase"
													        onClick={onAddToCart}/>
												</>
										}
									</div>
									{product.quantity <= 10 && <Button loading={requestInformationLoading}
                                                                       label="Être informé de la disponibilité"
                                                                       className="uppercase fps__button--secondary flex-1 mt-3"
                                                                       style={{height: "40px"}} onClick={onRequest}/>}
								</> :
								<>
									<Button loading={requestInformationLoading} label="Être informé de la disponibilité"
									        className="uppercase fps__button--secondary flex-1" onClick={onRequest}/>
									{props.edit && <Button icon="pi pi-trash" className="ml-3" onClick={onDeleteItem}
                                                           style={{width: "47px"}}/>}
								</>
						}
					</div>
				</div>
			</> : <div className="p-4 flex flex-column">
				<div className="fps__text--big text-center ">
					Aucun résultat trouvé pour la référence "{reference}"
				</div>
				<div className={"mt-5"}>
					<Button label="Revenir au catalogue" className="w-full fps__button--secondary"
					        onClick={() => navigate('/home/catalog')}/>
				</div>
			</div>}
		</LoadingDiv>
	)
}
