import {useContext} from "react";
import {FirebaseContext} from "../Contexts/FirebaseContext";
import {User as FirebaseUser} from "firebase/auth";
import {User} from "@Types/User";

export const useAuth = () => {
    const context = useContext(FirebaseContext);
    return context ? context.auth : undefined;
}

export const useStorage = () => {
    const context = useContext(FirebaseContext);
    return context ? context.storage : undefined;
}

export const useFunctions = () => {
    const context = useContext(FirebaseContext);
    return context ? context.functions : undefined;
}

export const useApp = () => {
    const context = useContext(FirebaseContext);
    return context ? context.app : undefined;
}

export const useFirestore = () => {
    const context = useContext(FirebaseContext);
    return context ? context.firestore : undefined;
}

export function useUser(authenticated?: false): {meta?: User, firebase?: FirebaseUser, loadUser?: () => Promise<void>, isConnected: boolean} | undefined;
export function useUser(authenticated: true): {meta: User, firebase: FirebaseUser, loadUser: () => Promise<void>, isConnected: boolean};
export function useUser(authenticated?: boolean): {meta?: User, firebase?: FirebaseUser, isConnected: boolean, loadUser?: () => Promise<void>} | undefined {

    const context = useContext(FirebaseContext);
    if (authenticated) {
        return {...context!.user!, isConnected: Boolean(context!.user!.meta && context!.user!.firebase)};
    }
    return context && context.user ? {...context.user, isConnected: Boolean(context.user.meta && context.user.firebase)} : undefined;
}
