import React from "react";
import "./QuotationDetailDialog.scss";
import {Dialog} from "primereact/dialog";
import {calculateTotalWithMargin, numberWithSpaces} from "@Utils/price.utils";
import { Quotation } from "@Types/Quotation";

export type QuotationDetailDialogProps = {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    quotation: Quotation;
}
export const QuotationDetailDialog: React.FC<QuotationDetailDialogProps> = props => {

    return (
        <Dialog appendTo="self" header="Détail du prix" visible={props.visible} onHide={() => props.setVisible(false)} closable>
            <div className="DetailModal py-3">
                <div className="DetailModal-line">
                    <div className="DetailModal-line-label"> Prix d'achat HT produits : </div>
                    <div className="DetailModal-line-price">
                        {numberWithSpaces(props.quotation.items.reduce((a, q) => q.product.price * q.quantity + a, 0))} €
                    </div>
                </div>
                <div className="DetailModal-line">
                    <div className="DetailModal-line-label"> Prix de vente HT produits : </div>
                    <div className="DetailModal-line-price">
                        {numberWithSpaces(calculateTotalWithMargin(props.quotation.items, 0))} €
                    </div>
                </div>
                <div className="DetailModal-line">
                    <div className="DetailModal-line-label"> Prestation : </div>
                    <div className="DetailModal-line-price">
                        {numberWithSpaces(props.quotation.extra_charge?.price || 0)} €
                    </div>
                </div>
                <div className="DetailModal-line mt-3">
                    <div className="DetailModal-lastLine-label"> Total vente HT : </div>
                    <div className="DetailModal-lastLine-price">
	                    {numberWithSpaces(calculateTotalWithMargin(props.quotation.items, props.quotation.extra_charge?.price))} €
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
