import {ApiClient} from "../../../models/Helpers";
import {httpsCallable} from "firebase/functions";
import {useMemo} from "react";
import {useFunctions} from "@Hooks/firebase";
import {useToast} from "@Hooks/toast";
import {CustomError} from "@Utils/Errors";

export const useApi = () => {

    const functions = useFunctions();
    const {error} = useToast();

    return useMemo(() => {
        return new Proxy<ApiClient>({} as any, {
            get: (_target, property: string) => {
                if (!functions) throw new Error('functions not initialized');
                return (body: any) => httpsCallable(functions, property)(body).then((res: any) => {
                    if ("result" in res.data && res.data.result !== "ok") throw new CustomError(res.data.result);
                    return res.data
                }).catch(err => {
                    error(err.code + " : " + err.message);
                    console.error();
                })
            }
        })
    }, [functions])

}
