import React, {useCallback, useState} from "react";
import {Order} from "@Types/Order";
import {useFirestore, useUser} from "@Hooks/firebase";
import {collection, getDocs} from "firebase/firestore";

export interface OrderContextI {
    orders: Order[];
    loadOrders: () => Promise<void>;
    isLoading: boolean;
    tmpOrder?: Order & {quotationUid: string};
    setTmpOrder: React.Dispatch<React.SetStateAction<(Order & {quotationUid: string}) | undefined>>;
}

export const OrderContext = React.createContext<OrderContextI>({
    isLoading: false,
    loadOrders() {
        return Promise.resolve();
    },
    orders: [],
    tmpOrder: undefined,
    setTmpOrder: () => {}
})

export const OrderProvider: React.FC<React.PropsWithChildren> = props => {

    const [orders, setOrders] = useState<Order[]>([]);

    const {meta} = useUser(true);
    const db = useFirestore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tmpOrder, setTmpOrder] = useState<(Order & {quotationUid: string}) | undefined>();


    const loadOrders = useCallback(() => {
        if (meta && db) {
            setIsLoading(true);
            const ordersRef = collection(db, 'users', meta.userUid, 'orders');
            return getDocs(ordersRef).then(res => {
                if (!res.empty) {
                    setOrders(res.docs.map(e => e.data() as Order));
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }
        return Promise.reject('nothing')
    }, [db, meta]);


    return <OrderContext.Provider value={{
        orders,
        loadOrders,
        isLoading,
        tmpOrder,
        setTmpOrder
    }}>
        {props.children}
    </OrderContext.Provider>

}
