import React, {useContext, useEffect} from "react";
import {PageHeaderContext} from "../Contexts/PageHeaderProvider";
import {LoginForm} from "@Pages/Auth/LoginForm";
import {ForgottenPassword} from "@Pages/Auth/ForgottenPassword";
import {SignupForm} from "@Pages/Auth/SignupForm";

export type AuthProps = {
    isLogin?: boolean;
    isForgottenPassword?: boolean;
    isSignup?: boolean;
}
export const Auth: React.FC<AuthProps> = props => {

    const {setTemplate} = useContext(PageHeaderContext);

    useEffect(() => {
        setTemplate('auth');
    }, []);

    return (
        <div className="Login flex flex-column align-items-center">
            {props.isLogin && <LoginForm/>}
            {props.isForgottenPassword && <ForgottenPassword />}
            {props.isSignup && <SignupForm />}
        </div>
    )
}
