import React, {useState, useEffect} from "react";
import {Product} from "@Types/Product";
import {useFirestore, useStorage } from "@Hooks/firebase";
import noProfile from "@Assets/images/no-profile-image.png";
import { ref, getDownloadURL } from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";
import { Profile } from "@Types/Profile";
import {numberWithSpaces} from "@Utils/price.utils";
import './ProductTechnicalInformations.scss';
import {classNames} from "primereact/utils";

const sortFieldOrder: Record<string, string[]> = {
	EN: [
		"Section (mm)",
		"Diamètre (mm)",
		"RSC (mm)",
		"CDR (mm)",
		"Pression max (bars)",
		"Charge à 25 km/h",
		"Charge à 25km/h",
		"Charge à 25Km/h",
		"Charge à 25Km/h (Kg)",
		"Charge à 40 km/h",
		"Charge à 40km/h",
		"Charge à 40Km/h",
		"Charge à 40Km/h (Kg)",
		"Jante"
	],
	RN: [
		"Nb de trous",
		"Déport",
		"Alésage Central",
		"Diamètre répartition goujon",
		"Diamètre goujon",
		"Epaisseur Jante",
		"Epaisseur Voile",
		"RAL",
		"PV et renfort",
		"Charge à 25 km/h",
		"Charge à 25km/h",
		"Charge à 25Km/h",
		"Charge à 25Km/h (Kg)",
		"Charge à 40 km/h",
		"Charge à 40km/h",
		"Charge à 40Km/h",
		"Charge à 40Km/h (Kg)"
	],
	RC: [
		"Section (mm)",
		"Diamètre (mm)",
		"RSC (mm)",
		"CDR (mm)",
		"Pression max (bars)",
		"Charge à 25 km/h",
		"Charge à 25km/h",
		"Charge à 25Km/h",
		"Charge à 25Km/h (Kg)",
		"Charge à 40 km/h",
		"Charge à 40km/h",
		"Charge à 40Km/h",
		"Charge à 40Km/h (Kg)",
		"Jante"
	]
};

export type ProductTechnicalInformationsProps = {
	product: Product,
	className?: string,
	contentStyle?:React.CSSProperties
}
export const ProductTechnicalInformations: React.FC<ProductTechnicalInformationsProps> = (props) => {

	const [profile, setprofile] = useState('');

	const storage = useStorage();
	const firestore = useFirestore();
	useEffect(() => {
		if (storage && props.product && firestore) {

			const profileName = (props.product.meta.PN_M_Profil || props.product.meta.RC_M_Profil).replaceAll(' ', '');

			let processedProfileName = "";

			switch (props.product.meta['Famille Divalto']) {
				case 'EN':
				case 'RC':
					processedProfileName = profileName;
					break;
				case 'RN':
					processedProfileName = 'default_RNU';
					break;
				case 'CH':
					processedProfileName = 'default_CHA';
					break;
				default:
					processedProfileName = profileName
					break;
			}

			const docRef = doc(firestore, 'profiles', processedProfileName);

			getDoc(docRef).then(profile => {
				if (!profile.exists()) throw new Error('Profile not found');

				const data: Profile = profile.data() as Profile;
				if (!data.url) throw new Error('No image for profile');

				getDownloadURL(ref(storage, data.url)).then(url => {
					setprofile(url);
				})
			}).catch(err => console.error(err));

		}
	}, [props.product, storage, firestore]);

    return (
        <div className={classNames(["ProductTechnicalInformations", props.className])}>
           <div className="ProductTechnicalInformations-header">
	           <div className="ProductTechnicalInformations-header-brand flex justify-content-end">
		           <img src={`/assets/images/${props.product.brand.trim().toLowerCase()}-logo.png`} />
	           </div>
	           <div className="ProductTechnicalInformations-header-profile flex justify-content-center">
		           <img src={!profile ? noProfile : profile} />
	           </div>
	           <div className="ProductTechnicalInformations-header-type mb-2">
		           {props.product.type}
	           </div>
	           <div className="ProductTechnicalInformations-header-information flex flex-column">
		           <div className="flex justify-content-between">
			           <span className="mb-1">Ref : {props.product.reference}</span>
			           <span className="ml-3">Prix BF : {numberWithSpaces(+props.product.meta['Prix Public'])} €</span>
		           </div>
		           <span>{props.product.des}</span>
	           </div>
           </div>
	        <div className="ProductTechnicalInformations-content flex flex-column" style={props.contentStyle}>
		        {Object.entries(props.product.meta).filter(([field]) => {
			        let family = props.product.meta['Famille Divalto'].trim();
			        family = family === "EN" ? "PN" : family;
			        return new RegExp(`^${family}_(T|TM|MT)_`, 'ig').test(field);
		        }).sort( (a, b) => {

			        const category = props.product.meta['Famille Divalto'].trim();

			        if (['EN', 'RN', 'RC', 'CH'].includes(category)) {
				        const aName = a[0].split('_')[2].trim().replaceAll(/\s/g, ' ');
				        const bName = b[0].split('_')[2].trim().replaceAll(/\s/g, ' ');

				        const indexA = sortFieldOrder[category].indexOf(aName);
				        const indexB = sortFieldOrder[category].indexOf(bName);

				        if (indexA === -1)
					        return 1;
				        else if (indexB === -1)
					        return -1;

				        return indexA - indexB;
			        } else {
				        return 1;
			        }

		        } ).map(([field, value]) => {
			        return value && <div key={field} className="ProductTechnicalInformations-content-line w-full flex justify-content-between mb-3">
                        <span className="ProductTechnicalInformations-content-line-label">{field.split('_')[2].trim()}</span>
                        <span className="ProductTechnicalInformations-content-line-value">{(isNaN(+value) ? value : (+value % 1 !== 0 ? (+value).toFixed(2) : value)) || ' - '}</span>
                    </div>
		        })}
	        </div>
        </div>
    )
}
