import React from "react";
import {Product} from "@Types/Product";
import {Badge} from "primereact/badge";
import './CatalogItem.scss';
import {classNames} from "primereact/utils";
import {numberWithSpaces} from "@Utils/price.utils";

export type CatalogItemProps = {
    product: Product;
    quantity?: number;
    showAvailability?: boolean;
    onClick?: (product: Product) => void;
}
export const CatalogItem: React.FC<CatalogItemProps> = props => {

    return (
        <div className="CatalogItem fps--card w-full p-0 py-3 my-3" onClick={() => props.onClick?.(props.product)}>
            <div className="CalatogItem__header flex px-3 justify-content-between mb-2">
                <div className="fps__text--bold">
                    {props.product.type.toUpperCase()}
                </div>
                <div>
                    <img src={`/assets/images/${props.product.brand.trim().toLowerCase()}-logo-mini.png`} alt={props.product.brand} style={{
                        maxHeight: "24px",
                        width: "auto"
                    }}/>
                </div>
            </div>
            <div className="fps__text--subtitle px-3">
                <div className="flex justify-content-between">
                    <div className="mr-3">
                        Ref : {props.product.reference}
                    </div>
                    <div>
                        Prix BF : {numberWithSpaces(+props.product.meta['Prix Public'])} €
                    </div>
                </div>
                <div>
                    {props.product.meta.DES}
                </div>
            </div>
            <div className="CatalogItem__price flex justify-content-start pt-3 px-3 mt-4">
                <div className="flex justify-content-start align-items-center">
                    {props.showAvailability !== false && <>
                        <div className="flex justify-content-center align-items-center mr-1">
                            {props.product.quantity <= 0 ? <Badge severity="danger"/> : (props.product.quantity <= 10 ? <Badge severity="warning"/> : <Badge severity="success"/>)}
                        </div>
                        <div>
                            {props.product.quantity <= 0 ? "Hors stock" : (props.product.quantity <= 10 ? `Stock limité (${props.product.quantity})` : "Disponible")}
                        </div>
                    </>}
                </div>
                <div className={classNames(["ml-auto",  'fps__text--medium', props.product.hasDiscount && 'CatalogItem__price-promo'])}>
                    {numberWithSpaces(props.product.price)} € UHT
                    {props.product.hasDiscount && !props.quantity && <i className="pi pi-tag ml-2" style={{fontSize: 12}}/>}
                </div>
                {props.quantity && <div className="ml-2 CatalogItem__price-quantity">
                    x {props.quantity}
                </div>}
            </div>
            {/*<div className="CatalogItem__price flex justify-content-start pt-3 px-3 mt-4">*/}
            {/*    <div className="flex justify-content-start align-items-center">*/}
            {/*        {props.showAvailability !== false && <>*/}
            {/*            <div className="flex justify-content-center align-items-center mr-1">*/}
            {/*                {props.product.quantity <= 0 ? <Badge severity="danger"/> : (props.product.quantity <= 10 ? <Badge severity="warning"/> : <Badge severity="success"/>)}*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                {props.product.quantity <= 0 ? "Hors stock" : (props.product.quantity <= 10 ? `Stock limité (${props.product.quantity})` : "Disponible")}*/}
            {/*            </div>*/}
            {/*        </>}*/}
            {/*    </div>*/}
            {/*    <div className={classNames(["ml-auto", props.product.hasDiscount ? 'fps__text--discount-strike' : ' fps__text--medium'])}>*/}
            {/*        {numberWithSpaces(props.product.hasDiscount && props.product.originalPrice? props.product.originalPrice : props.product.price)} € UHT*/}
            {/*    </div>*/}
            {/*    {props.quantity && <div className="ml-2 CatalogItem__price-quantity">*/}
            {/*        x {props.quantity}*/}
            {/*    </div>}*/}
            {/*</div>*/}
            {/*{*/}
            {/*    props.product.hasDiscount && <div className="flex px-3 justify-content-start align-items-center mt-3">*/}
            {/*        <div className="flex justify-content-start align-items-center">*/}
            {/*            Promotion*/}
            {/*        </div>*/}
            {/*        <div className={classNames(["ml-auto", 'fps__text--discount-highlight'])}>*/}
            {/*            {numberWithSpaces(props.product.price)} € UHT*/}
            {/*        </div>*/}
            {/*        {props.quantity && <div className="ml-2 CatalogItem__price-quantity">*/}
            {/*            x {props.quantity}*/}
            {/*        </div>}*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    )
}
