import React from "react";
import "./InformationPersonal.scss"
import {useAuth, useUser} from "@Hooks/firebase";
import {useFormik} from "formik";
import {User} from "@Types/User";
import {InputText} from "primereact/inputtext";
import {userSchema} from "@Schemas/user.schema";
import {usePromise} from "@Hooks/promise";
import {Button} from "primereact/button";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {sendPasswordResetEmail} from "firebase/auth";

export type InformationPersonalProps = {}
export const InformationPersonal: React.FC<InformationPersonalProps> = (props) => {

    const {meta: authUserMeta} = useUser(true);
    const Api = useApi();

    const [userUpdate, userUpdateMap] = usePromise(async (data: User) => {
        const res = await Api.user_call_updateClient(data);
        if (res.result === "ok") {
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    });

    const {promise} = useToast();

    const userFormik = useFormik<User>({
        initialValues: authUserMeta,
        validationSchema: userSchema,
        onSubmit: data => promise(userUpdate(authUserMeta.userUid)(data), {
            pending: "Mise à jour de vos informations en cours ...",
            error: "Erreur survenue lors de la mise à jour",
            success: "Votre compte a été mis à jour !"
        }),
        validateOnMount: true
    });

    const setFormik = (field: keyof User) => (value: string) => {
        userFormik.setFieldValue(field, value, true);
    }

	const auth = useAuth();
	const user = useUser();

	const onUpdatePassword = () => {
		if (auth && user?.firebase?.email) {
			promise(sendPasswordResetEmail(auth, user.firebase.email), {
				pending: "Envoi d'un mail de réinitialisation du mot de passe en cours..",
				success: "Mail de réinitialisation envoyé",
				error: "Une erreur est survenue lors de l'envoi du mail"
			});
		}
	}

    return (
        <form onSubmit={userFormik.handleSubmit} className={"InformationPersonal relative"}>
            <div className={'InformationPersonal-subtitle mb-4'}>
                Information personnelles
            </div>
            <div className="flex flex-column w-full mb-3">
                <label htmlFor="firstname" className="mb-2">Nom</label>
                <InputText value={userFormik.values.firstname} onChange={e => setFormik('firstname')(e.currentTarget.value)}/>
            </div>
            <div className="flex flex-column w-full mb-3">
                <label htmlFor="lastname" className="mb-2">Prénom</label>
                <InputText value={userFormik.values.lastname} onChange={e => setFormik('lastname')(e.currentTarget.value)}/>
            </div>
            <div className="flex flex-column w-full mb-3">
                <label htmlFor="phone" className="mb-2">Téléphone</label>
                <InputText value={userFormik.values.phone} onChange={e => setFormik('phone')(e.currentTarget.value)}/>
            </div>
            <div className="flex flex-column w-full mb-3">
                <label htmlFor="email" className="mb-2">Email</label>
                <InputText disabled value={authUserMeta.email} style={{opacity: 0.5}}/>
            </div>
            <div className="fps__text--link text-left" onClick={onUpdatePassword}>
                Modifier mot de passe
            </div>
            <div className="w-full p-4 bg-white fixed bottom-0 left-0">
                <Button type="button" disabled={!userFormik.isValid}  className="w-full flex align-items-center justify-content-center" onClick={() => userFormik.handleSubmit()} >
                    <i className={userUpdateMap.get(authUserMeta.userUid) ? "pi pi-spin pi-spinner mr-3" : "pi pi-save mr-3"}/>
                    <div>Sauvegarder les changements</div>
                </Button>
            </div>
        </form>
    )
}
