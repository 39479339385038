import React, {PropsWithChildren, useCallback, useMemo, useState} from "react";
import logo from "@Assets/images/logo.svg";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {Sidebar} from "primereact/sidebar";
import {PageHeader} from "@Components/PageHeader";


export interface PageHeaderPayload {
    left: {
        icon?: string;
        active?: boolean;
        action?: () => void;
        badge?: string;
    },
    right: {
        icon?: string;
        active?: boolean;
        action?: () => void;
        badge?: string;
    }
}

type Templates = "default" | 'auth';
export interface IPageHeaderContext {
    setHeader: React.Dispatch<React.SetStateAction<Partial<PageHeaderPayload>>>;
    header: Partial<PageHeaderPayload>;
    setTemplate: (template: Templates) => void;
}


export const PageHeaderContext = React.createContext<IPageHeaderContext>({
    setHeader: () => undefined,
    header: {},
    setTemplate: () => undefined
})

export const PageHeaderProvider: React.FC<PropsWithChildren<object>> = props => {

    const templates: {
        [k in Templates] : Partial<PageHeaderPayload>
    } = useMemo(() => ({
        default: {
            left: {
                icon: 'pi pi-bars',
            },
            right: {
                icon: 'pi pi-shopping-cart'
            }
        },
        auth : {}
    }), [])


    const [header, setHeader] = useState<Partial<PageHeaderPayload>>(templates.default);

    const setTemplate = useCallback((template: Templates) => {
        setHeader(templates[template]);
    }, [templates])

    return (
        <PageHeaderContext.Provider value={{setHeader, header, setTemplate}}>
            <PageHeader />
            {props.children}
        </PageHeaderContext.Provider>
    )
}


