import React, {useCallback, useEffect, useState} from "react";
import {Quotation} from "@Types/Quotation";
import {useFirestore, useUser} from "../hooks/firebase";
import {collection, getDocsFromServer, onSnapshot} from "firebase/firestore";
import {useNetworkState} from "react-use";

export interface QuotationContextI {
    quotations: Quotation[];
    tmpQuotation?: Quotation;
    setTmpQuotation: React.Dispatch<React.SetStateAction<Quotation | undefined>>;
}

export const QuotationContext = React.createContext<QuotationContextI>({
    quotations: [],
    tmpQuotation: undefined,
    setTmpQuotation: () => {
    },
})
export type QuotationContextProps = {}
export const QuotationProvider: React.FC<React.PropsWithChildren<QuotationContextProps>> = props => {

    const [tmpQuotation, setTmpQuotation] = useState<Quotation | undefined>();
    const [quotations, setQuotations] = useState<Quotation[]>([]);

    const {meta} = useUser(true);
    const db = useFirestore();

    useEffect(() => {
        if (db && meta) {
            const colRef = collection(db, "users", meta.userUid, "quotations");
            return onSnapshot(colRef, {includeMetadataChanges: true}, querySnapshot => {
                const quots = querySnapshot.docs.map(q => q.data() as Quotation);
                setQuotations(quots.sort((a,b) => a.created_at < b.created_at ? 1 : -1));
            })
        }
    }, [db, meta]);

    return (
        <QuotationContext.Provider value={{
            quotations,
            setTmpQuotation,
            tmpQuotation,
        }}>
            {props.children}
        </QuotationContext.Provider>
    )
}
