import React, {useState} from "react";
import "./CustomDropdown.scss";
import {classNames} from "primereact/utils";
import {Dialog} from "primereact/dialog";

export type CustomDropdownProps = {
    value: string;
    onChange: (string: any) => void;
    options: {label: any; value: any}[];
    className: string;
}
export const CustomDropdown: React.FC<CustomDropdownProps> = props => {

    const [visible, setVisible] = useState<boolean>(false);

    const onChange = (value: string) => {
        setVisible(false);
        props.onChange(value);
    }


    return (
        <>
            <Dialog dismissableMask  className="CustomDropdown-dialog" contentClassName="border-round-md p-3" position="top" showHeader={false} footer="" visible={visible} onHide={() => setVisible(false)}>
                {props.options.map(option => {
                    return <div key={option.value} className={classNames(["CustomDropdown-dialog-option", option.value === props.value && "CustomDropdown-dialog-option-selected"])} onClick={e => {
                        e.stopPropagation();
                        onChange(option.value)
                    }}>
                        {option.label}
                    </div>
                })}
            </Dialog>
            <div className={classNames(["CustomDropdown flex cursor-pointer px-2", props.className])} onClick={e => {
                setVisible(true)
            }}>
                <div className="CustomDropdown-label flex justify-content-center align-items-center mr-3">
                    {props.options.find(o => o.value === props.value)?.label || ' - '}
                </div>
                <div className="flex justify-content-center align-items-center">
                    <i className="pi pi-angle-down"/>
                </div>
            </div>
        </>
    )
}
