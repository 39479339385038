import React from "react";
import {Route, Routes} from "react-router-dom";
import {ProfileSections} from "@Pages/Home/Profile/ProfileSections";
import {InformationPersonal} from "@Pages/Home/Profile/InformationPersonal";
import {MyAddresses} from "@Pages/Home/Profile/MyAddresses";
import {MyAgencies} from "@Pages/Home/Profile/MyAgencies";


export type ProfileProps = {}

export const Profile: React.FC<ProfileProps> = (props) => {

    return (
        <Routes>
            <Route path={'/'} element={<ProfileSections/>}/>
            <Route path={'/my-informations'} element={<InformationPersonal/>}/>
            <Route path={'/my-addresses'} element={<MyAddresses />}/>
            <Route path={"/my-agencies"} element={<MyAgencies />} />
        </Routes>
    )

}
