import React from "react";
import "./ForgottenPassword.scss";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {useAuth} from "@Hooks/firebase";
import {sendPasswordResetEmail} from "firebase/auth";
import {useToast} from "@Hooks/toast";
import {useNavigate} from "react-router-dom";

export type ForgottenPasswordProps = Record<string, never>
export const ForgottenPassword: React.FC<ForgottenPasswordProps> = () => {

    const auth = useAuth();

    const {success, error} = useToast();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit: async (values) => {
            try {
                if (auth) {
                    await sendPasswordResetEmail(auth, values.email);
                    success('Mail de réinitialisation envoyé ! ');
                }
            } catch (e) {
                error("")
            }
        }
    });

    function onGoBack() {
        navigate('/login');
    }

    return (
        <div className="LoginForm flex flex-column justify-content-center px-4" style={{marginTop: "162px"}}>
            <div className="fps__text--big mb-5">
                MOT DE PASSE OUBLIÉ ?
            </div>
            <div className="flex align-items-center w-full p-input-icon-left mb-3">
                <i className="pi pi-at fps__text--placeholder-icon" />
                <InputText
                    id="email"
                    name="email"
                    className="w-full"
                    placeholder="Saisir adresse mail"
                    onChange={formik.handleChange}
                    value={formik.values.email}/>
            </div>
            <Button label="ENVOYER LA DEMANDE" className="fps__button--normal" onClick={() => formik.submitForm()}/>
            <Button label="REVENIR A L'ESPACE CONNEXION" className="mt-2 fps__button--secondary-no-border" onClick={onGoBack}/>
        </div>
    )
}
