import { useToast } from "@Hooks/toast";
import { User } from "@Types/User";
import { getAnalytics, setUserId, type Analytics } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import {
	Auth,
	User as FirebaseUser,
	browserLocalPersistence,
	getAuth,
	onAuthStateChanged,
	setPersistence
} from "firebase/auth";
import {
	CACHE_SIZE_UNLIMITED,
	Firestore,
	collection,
	disableNetwork,
	doc,
	enableIndexedDbPersistence,
	enableNetwork,
	getDoc,
	initializeFirestore
} from "firebase/firestore";
import { Functions, getFunctions, httpsCallable } from "firebase/functions";
import { Messaging, getMessaging, getToken, onMessage } from "firebase/messaging";
import { FirebaseStorage, getStorage } from "firebase/storage";
import LogRocket from "logrocket";
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNetworkState } from 'react-use';
import { firebaseConfig } from "../config/firebase";

export interface IFirebaseContext {
	firestore: Firestore,
	app: FirebaseApp,
	functions: Functions,
	auth: Auth,
	storage: FirebaseStorage,
	messaging: Messaging,
	analytics: Analytics
	token?: string,
	user?: { firebase?: FirebaseUser, meta?: User, loadUser?: () => any },
}

export const FirebaseContext = React.createContext<IFirebaseContext | null>(null)

export const FirebaseProvider: React.FC<PropsWithChildren<{}>> = (props) => {

	const [app] = useState<FirebaseApp>(initializeApp(firebaseConfig));
	const [loading, setLoading] = useState<boolean>(true);

	const [user, setUser] = useState<IFirebaseContext["user"]>(undefined);

	const [notificationToken, setNotificationToken] = useState<string>();


	const {notification} = useToast();

	const onTokenLoaded = async (token: string) => {
		const res = await httpsCallable<any, any>(context.functions, 'user_call_registerBrowser')({
			fcmToken: token
		});

		if (res.data?.result === 'ok') console.debug('Browser saved');
	}

	const {online} = useNetworkState();

	const [context, setContext] = useState({
		functions: getFunctions(app, 'europe-west3'),
		firestore: initializeFirestore(app, {
			cacheSizeBytes: CACHE_SIZE_UNLIMITED
		}),
		analytics: getAnalytics(app),
		auth: getAuth(app),
		messaging: getMessaging(app),
		app: app,
		storage: getStorage(app),
	});

	const loadUser = useCallback((user: FirebaseUser, firestore: Firestore) => {
		if (user) {
			let unsub: () => void;
			const collectionRef = collection(firestore, 'users');
			const docRef = doc(collectionRef, user.uid);

			getDoc(docRef).then(res => {
				const meta = res.data() as User;
				setUser({
					meta,
					firebase: user,
					loadUser: () => {
						loadUser(user, firestore)
					}
				});

				setUserId(getAnalytics(app), user.uid);
				LogRocket.identify(user.uid, {
					name: `${meta.firstname} ${meta.lastname}`,
					email: user.email || '',
					role: meta.role,
				})
				return meta;
			}).then(meta => {
				if (Notification.permission === 'granted') {
					getToken(context.messaging, {
						vapidKey: 'BP9zLO_fJBSuEeWr3PyY-LzeM4G63-I7AEI27WI1S9pBtaYCgA88I4B0_RwCj5WgVQe02TWkopFG4aUVmaC1zFQ'
					}).then(token => {
						if (token) {
							setNotificationToken(token);
							onTokenLoaded(token);
							unsub = onMessage(context.messaging, (payload) => {
								if (payload.notification?.body)
									notification(payload.notification.body)
							})
						}
					})
				} else if (Notification.permission === 'default' && meta.accepted_notifications) {
					Notification.requestPermission().then(permission => {
						if (permission === "granted") {
							getToken(context.messaging, {
								vapidKey: 'BP9zLO_fJBSuEeWr3PyY-LzeM4G63-I7AEI27WI1S9pBtaYCgA88I4B0_RwCj5WgVQe02TWkopFG4aUVmaC1zFQ'
							}).then(token => {
								if (token) {
									setNotificationToken(token);
									onTokenLoaded(token);
									unsub = onMessage(context.messaging, (payload) => {
										if (payload.notification?.body)
											notification(payload.notification.body)
									})
								}
							})
						}
					})
				}
			})

			return () => unsub?.();
		}
	}, [context]);


	useEffect(() => {
		if (app) {

			// const host = 'localhost';
			// connectAuthEmulator(context.auth, `http://${host}:9099`, {disableWarnings: true});
			// connectFirestoreEmulator(context.firestore, host, 8080);
			// connectStorageEmulator(context.storage, host, 9199);
			// connectFunctionsEmulator(context.functions, host, 5001);

			void enableIndexedDbPersistence(context.firestore).catch(err => {
				console.error(err.code)
			});

			void setPersistence(context.auth, browserLocalPersistence);

			onAuthStateChanged(context.auth, (user) => {
				if (user) {
					void loadUser(user, context.firestore);

				} else {
					setUser({})
				}
				setLoading(false);
			});
		}

	}, [app]);

	useEffect(() => {
		if (online) {
			enableNetwork(context.firestore).then(() => {
				console.info('Network enabled');
			})
		} else {
			disableNetwork(context.firestore).then(() => {
				console.info('Network disabled');
			})
		}
	}, [online, context]);

	return (<FirebaseContext.Provider value={{...context, user, token: notificationToken}}>
		{!loading && user !== undefined && props.children}
	</FirebaseContext.Provider>)
}
