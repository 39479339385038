import React from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {classNames} from "primereact/utils";
import styled from "styled-components";

export interface CustomEventDialogProps {
    title?: string;
    text?: string;
    visible: boolean;
    showIcon?: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    actions: {
        label: string; action: () => void, classNames?: string[]
    }[];
}

const DialogText = styled.div`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 8px;
  color: #98A2B3;
`

export const CustomEventDialog: React.FC<CustomEventDialogProps> = (props) => {

    return <Dialog
        visible={props.visible}
        onHide={() => props.setVisible(false)}
        style={{width: "342px"}}
        closable={false}
        showHeader={false}
        dismissableMask
        appendTo="self"
        footer={<div className="flex flex-column">
            {props.actions.map(action => {
                return <Button key={action.label} label={action.label} onClick={action.action}
                               className={classNames(["w-full", ...(action.classNames || [])])}/>
            })}
        </div>}
        contentClassName="border-round-top-lg px-4 pt-4"
    >
        <div className="flex flex-column justify-content-center align-items-center">
            {props.showIcon !== false && <i className="pi pi-check-circle mb-3" style={{fontSize: "38px", color: "#2ECC71"}}/>}
            {
                props.title && <div className="fps__text--bold uppercase text-center">
                    {props.title}
                </div>
            }
            {props.text && <DialogText>
                {props.text}
            </DialogText>}
        </div>
    </Dialog>
}
