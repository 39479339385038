import React, {useCallback, useEffect, useState} from "react";
import {SwippableModal} from "@Components/SwippableModal";
import {QuotationExtra} from "@Types/Quotation";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import { Button } from "primereact/button";
import './QuotationExtraModal.scss';
import {numberWithSpaces} from "@Utils/price.utils";

export type QuotationExtraModalProps = {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    totalHT: number,
    extra?: QuotationExtra,
    setExtra?: (extra: QuotationExtra | undefined) => void,
}
export const QuotationExtraModal: React.FC<QuotationExtraModalProps> = props => {

    const [label, setLabel] = useState<string>(props.extra?.label || '');
    const [price, setPrice] = useState<number>(props.extra?.price || 0);

    const onSave = useCallback(() => {
        props.setExtra?.({
            label,
            price
        });
        props.setVisible(false);
    }, [label, price, props]);

    const onDelete = useCallback(() => {
        props.setExtra?.(undefined);
        props.setVisible(false);
    }, [props])

    return (
        <SwippableModal visible={props.visible} onHide={() => props.setVisible(false)}>
            <div className="QuotationExtraModal w-full h-auto">
                <div className="fps__text--big mb-4">
                    SAISIR LE MONTANT
                </div>
                <div className="mb-3">
                    <InputText className="w-full mb-3" placeholder="Saisir un libellé" value={label} onChange={e => setLabel(e.target.value)}/>
                    <span className="p-input-icon-left w-full">
                    <i className="pi pi-euro"/>
                    <InputNumber mode="decimal" minFractionDigits={2} className="w-full" placeholder="Saisir un montant" value={price} onChange={e => setPrice(e.value || 0)}/>
                </span>
                </div>
                <div className="QuotationExtraModal-price w-full flex justify-content-between align-items-center" style={{marginBottom: 50}}>
                    <div className="QuotationExtraModal-price-label">
                        Prix total HT:
                    </div>
                    <div className="QuotationExtraModal-price-price">
                        {numberWithSpaces(props.totalHT)} €
                    </div>
                </div>
                <Button label="VALIDER LA PRESTATION" className="w-full" onClick={onSave}/>
                <Button label="SUPPRIMER LA PRESTATION" className="w-full fps__button--secondary-no-border mt-2" onClick={onDelete}/>
            </div>
        </SwippableModal>
    )
}
