import {Flip, toast, ToastOptions, ToastPosition, UpdateOptions} from "react-toastify";
import produce, {nothing} from "immer";
import {useMemo, useState} from "react";

export const useToast = (_position: ToastPosition = toast.POSITION.TOP_RIGHT, _delay: number = 2500) => {

    const [position] = useState<ToastPosition>(_position);
    const [delay] = useState<number>(_delay);

    const baseOptions: ToastOptions = useMemo(() => ({
        position, autoClose: delay, closeButton: true, theme: 'colored', transition: Flip, draggablePercent: 30
    }), [delay, position]);

    return useMemo(() => ({
        error: (message: string) => toast.error(message, baseOptions),
        info: (message: string) => toast.info(message, baseOptions),
        warn: (message: string) => toast.warn(message, baseOptions),
        success: (message: string) => toast.success(message, baseOptions),
        notification: (message: string) => toast(message, {
            type: toast.TYPE.INFO,
            autoClose: false,
            position: toast.POSITION.TOP_CENTER,
            theme: 'colored',
            draggablePercent: 20,
            closeOnClick: true,
            closeButton: false,
            icon: "🔔"
        }),
        promise: (...args: typeof toast.promise extends (...params: infer A) => any ? A : never) => {
            const [promise, _options, ...others] = args;

            const optionsGenerator = produce<string | UpdateOptions<any> | undefined, ['error' | 'success' | 'pending']>((draft, key) => {
                if (typeof draft === 'string') {
                    return {
                        ...baseOptions, render: draft, ...key === "pending" ? {type: "info"} : {}
                    };
                } else if (draft !== undefined) {
                    return {
                        ...baseOptions, ...key === "pending" ? {type: "info"} : {}, ...draft,
                    }
                } else {
                    return nothing;
                }
            });

            const options = produce(_options, draft => {
                draft.error = optionsGenerator(draft.error, 'error');
                draft.success = optionsGenerator(draft.success, 'success');
                draft.pending = optionsGenerator(draft.pending, 'pending');
            });

            return toast.promise(promise, options, ...others);
        },
    }), [baseOptions]);
}
