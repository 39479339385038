import React, {useContext, useEffect} from "react";
import './MyOrders.scss';
import {OrderContext} from "@Contexts/OrderContext";
import {DateTime} from "luxon";
import {useNavigate} from "react-router-dom";

export type MyOrdersProps = Record<string, never>
export const MyOrders: React.FC<MyOrdersProps> = () => {

    const {orders, loadOrders} = useContext(OrderContext);

    useEffect(() => {
        loadOrders();
    }, [loadOrders]);

    const navigate = useNavigate();

    return (
        <div className="MyOrders py-4">
            <div className="MyOrders-title uppercase px-4 pb-2">
                Mes commandes ({orders.length})
            </div>
            <div className="MyOrders-subtitle px-4 pb-5">
                Commandes passées depuis l'application
            </div>
            {orders.map(order => {
                return <div key={order.orderUid} className="MyOrders-order p-4 bg-white mb-1" onClick={e => {
                    navigate(`/home/my-orders/${order.orderUid}`, {
                        state: order
                    });
                }}>
                    <div className="MyOrders-order-title mb-2">N°{order.orderUid.substring(0,6).toUpperCase()} - {order.shipping_details.name}</div>
                    <div className="MyOrders-order-agency">{DateTime.fromISO(order.createdAt).toFormat('dd/MM/yy')} - {order.client.selectedAgency.name}</div>
                    <div className="MyOrders-order-price">{order.items.reduce((a,c) => a + c.quantity * c.product.price, 0).toFixed(2).replace('.', ',')} €</div>
                </div>
            })}
        </div>
    )
}
