import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {CartContext} from "@Contexts/CartProvider";
import './MyCart.scss';
import {CatalogItem} from "@Components/CatalogItem";
import {Button} from "primereact/button";
import {PageHeaderContext} from "@Contexts/PageHeaderProvider";
import produce from "immer";
import {useNavigate} from "react-router-dom";
import {useNetworkState} from "react-use";
import {CatalogContext} from "@Contexts/CatalogContext";
import {CustomEventDialog} from "@Components/CustomEventDialog";
import {QuotationContext} from "@Contexts/QuotationContext";
export type MyCartProps = Record<string, never>
export const MyCart: React.FC<MyCartProps> = () => {

    const {cart, total, totalStr, checkCart} = useContext(CartContext);
    const {setHeader} = useContext(PageHeaderContext);
    const {isLoading} = useContext(CatalogContext);
    const {setTmpQuotation} = useContext(QuotationContext);
    const [visibleQuotation, setVisibleQuotation] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        checkCart();
        setHeader(prev => produce(prev, draft => {
            draft.right = {
                icon: 'pi pi-times',
                action: () => {
                    setHeader(current =>({...current, right: prev.right}));
                    navigate('/home');
                }
            }
            return draft
        }));
    }, []);

    const {online} = useNetworkState();

    const isValid = useMemo(() => {
        return cart.every(e => e.product.quantity > 0) && online && cart.length > 0 && !isLoading
    }, [cart, isLoading, online])

    const onOrder = () => navigate('/home/order');

    const onShowCreateQuotation = () => {
        setVisibleQuotation(true);
    }

    const onCreateQuotation = useCallback(() => {
        if (isValid) {
            setTmpQuotation({
                client: {
                    name: '',
                    phone: '',
                    mail: ''
                },
                items: cart.map(e => ({...e, margin: 0})),
                quotationUid: '',
                created_at: '',
                validated: false,
                validated_at: ''
            });
            navigate('/home/quotation')
        }
    }, [isValid])

    return (
        <div className="MyCart">
            <CustomEventDialog
                showIcon={false}
                title="Êtes vous sur de vouloir transformer votre panier en devis ?"
                visible={visibleQuotation}
                setVisible={setVisibleQuotation}
                actions={[{
                    label: 'FAIRE UN DEVIS POUR MON CLIENT',
                    action: () => onCreateQuotation()
                }, {
                    label: 'ANNULER',
                    action: () => setVisibleQuotation(false),
                    classNames: ['fps__button--secondary-no-border']
                }]} />
            <div className="MyCart-title">
                MON PANIER ({cart.length})
            </div>
            <div className="MyCart-products mt-3">
                {cart.map(item => <CatalogItem key={item.product.reference} product={item.product} quantity={item.quantity} onClick={() => {navigate(`/home/${item.product.reference}/edit`, {
                    state: item.product
                })}}/>)}
            </div>
            <div className="fps--card-variant w-full flex flex-column justify-content-center align-items-center fixed left-0 bottom-0 p-4" style={{background:"white"}}>
                <div className="flex justify-content-between align-items-center w-full mb-3">
                    <div className="fps__text--small">Total HT :</div>
                    <div className="fps__text--medium-bold">{totalStr(total)} €</div>
                </div>
                <Button loading={isLoading} label="VALIDER MA COMMANDE" disabled={!isValid} onClick={onOrder} className="w-full"/>
                <Button loading={isLoading} label="FAIRE UN DEVIS POUR VOTRE CLIENT" disabled={!isValid} onClick={onShowCreateQuotation} className="w-full fps__button--secondary mt-3"/>
            </div>
        </div>
    )
}
