import {persistentMap} from "@nanostores/persistent";
import {CartItem} from "@Types/Order";
import {action, WritableStore} from "nanostores";
import produce from "immer";
import {User} from "@Types/User";
import {Product} from "@Types/Product";


export type ICartStore = {
    [id: string]: CartItem[]
};

export const CartStore = persistentMap<ICartStore>('cart', {}, {
    encode: p => {
        return JSON.stringify(p)
    },
    decode: p => {
        try {
            return JSON.parse(p);
        } catch (e) {
            return []
        }
    }
});

export type ActionStore = WritableStore<ICartStore>;
export type ActionFunction<T = CartItem> = (store: ActionStore, user: User, item: T) => void;

export const updateUserCart = action<ActionStore, ActionFunction<CartItem[]>>(CartStore, 'update', (store, user, items) => {
    const cur = store.get();
    store.set(produce(cur, draft => {
        if (user.userUid in draft) {
            draft[user.userUid] = items;
        } else {
            draft = {
                ...draft,
                [user.userUid]: items
            };
        }
        return draft;
    }))
});

export const addItem = action<ActionStore, ActionFunction>(CartStore, 'add-item', (store, user, item) => {
    const cur = store.get();
    store.set(produce(cur, draft => {
        if (user.userUid in draft) {
            draft[user.userUid].push(item);
        } else {
            draft = {
                ...draft,
                [user.userUid]: [item]
            };
        }
        return draft;
    }))
});

export const removeItem = action<ActionStore, ActionFunction<Product>>(CartStore, 'remove-item', (store, user, product) => {
    const cur = store.get();
    store.set(produce(cur, draft => {
        if (user.userUid in draft) {
            draft[user.userUid] = draft[user.userUid].filter(p => p.product.reference !== product.reference);
        } else {
            draft = {
                ...draft,
                [user.userUid]: []
            };
        }
        return draft;
    }))
});

export const updateItem = action<ActionStore, ActionFunction>(CartStore, 'update-item', (store, user, item) => {
    const cur = store.get();
    store.set(produce(cur, draft => {
        if (user.userUid in draft) {
            draft[user.userUid] = draft[user.userUid].map(p => {
                if (p.product.reference !== item.product.reference) return p;
                return item
            });
        } else {
            draft = {
                ...draft,
                [user.userUid]: []
            }
        }
        return draft;
    }))
});
