import {useCallback, useState} from "react";
import produce from "immer";

type AsyncFunction<Args extends any[], Return> = (...args: Args) => Promise<Return>;

export const usePromise = <Return, Args extends Array<any>>(promiseCallback: AsyncFunction<Args, Return>):
    [(id?: string) => AsyncFunction<Args, Return>, Map<string, boolean>, Map<string, Promise<Return>>] => {

    const [statusMap, setStatusMap] = useState<Map<string, boolean>>(new Map());

    const [promiseMap, setPromiseMap] = useState<Map<string, Promise<Return>>>(new Map());

    const promise = useCallback((id: string = 'uniq') => (...args: Args) => new Promise<Return>((resolve, reject) => {
        setStatusMap(prev => produce(prev, draft => {
            draft.set(id, true)
        }))

        const _promise = promiseCallback(...args);

        setPromiseMap(prev => produce(prev, draft => {
            draft.set(id, _promise);
        }))

        _promise.then((res) => {
            resolve(res);
        }).catch(err => {
            reject(err);
        }).finally(() => {
            setStatusMap(prev => produce(prev, draft => {
                draft.set(id, false)
            }));
            setPromiseMap(prev => produce(prev, draft => {
                draft.delete(id)
            }))
        })
    }), [promiseCallback]);

    return [promise, statusMap, promiseMap];
}
