import React, {useCallback, useMemo, useState} from "react";
import {useAuth, useUser} from "@Hooks/firebase";
import {useNavigate} from "react-router-dom";

import './ProfileSections.scss'
import {InputSwitch} from "primereact/inputswitch";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
export const ProfileSections : React.FC = (props) =>{

    const auth = useAuth();

    const navigate = useNavigate();

    const {meta, loadUser} = useUser(true);

    const { success } = useToast();

    const Api = useApi();
	const [loading, setLoading] = useState<boolean>(false);


    const notification = useMemo(() => {
        return Notification.permission === "granted"
    }, []);

    const sectionItems = useMemo(() => {
        return [{
            label: 'Informations personnelles',
            action: () => navigate('/home/profile/my-informations')
        }, {
            label: 'Mes agences',
            action: () => navigate('/home/profile/my-agencies')
        }, {
            label: 'Mes adresses favorites',
            action: () => navigate('/home/profile/my-addresses')
        }]
    }, [auth]);

    const onChangeNotifications = useCallback((value: boolean) => {
		setLoading(true);
        if (Notification.permission === 'default' && value) {
            Notification.requestPermission().then(() => {
                Api.user_call_updateClient({
                    ...meta,
                    accepted_notifications: value
                }).then((res) => {
                    if (res.result === 'ok') {
                        success("Information mis à jour !");
                        loadUser()
                    }
                }).finally(() => {
	                setLoading(false)
                })
            })
        } else if (Notification.permission === 'granted') {
            Api.user_call_updateClient({
                ...meta,
                accepted_notifications: value
            }).then((res) => {
                if (res.result === 'ok') {
                    success("Information mis à jour !");
                    loadUser()
	                setLoading(false)
                }
            }).finally(() => {
				setLoading(false);
            })
        }
    }, [meta])

    return (

        <div className={"ProfileSections"}>
            <div className="ProfileSections-title">
                MON COMPTE
            </div>
            <div className={"ProfileSections-section"}>
                <div className="flex justify-content-start align-items-center mb-3" style={{opacity: Notification.permission === ("denied" || loading) ? "30%" : "100%"}}>
                    <div className="mr-3" >
                        <InputSwitch checked={meta.accepted_notifications} onChange={(event: any) => onChangeNotifications(event.value)}/>
                    </div>
                    <div>
	                    {loading ? <i className="pi pi-spin pi-spinner"/> : "Accepter de recevoir des notifications promotionnelles"}
                    </div>
                </div>
                {sectionItems.map((items, index) => (
                    <div className={'ProfileSections-section__items'} onClick={items.action} key={index}>
                        <div className={''}>{items.label}</div>
                        <i className={'pi pi-angle-right'}/>
                    </div>
                ))}
            </div>

        </div>
    )
}
