import type { FirebaseOptions } from "firebase/app";

export const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyCXj7mBHz2mKn9m82-jpJ-STGrIfCTN8pE",
    authDomain: "france-pneu-selection-dev.firebaseapp.com",
    projectId: "france-pneu-selection-dev",
    storageBucket: "france-pneu-selection-dev.appspot.com",
    messagingSenderId: "288673301959",
	measurementId: "G-GCMSFK118R",
    appId: "1:288673301959:web:7d0d7a96da61675f33c5e2",
}
