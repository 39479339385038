import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Product} from "@Types/Product";
import {Button} from "primereact/button";
import './Filter.scss';
import {Dropdown} from "primereact/dropdown";
import produce from "immer";
import {classNames} from "primereact/utils";
import {Checkbox} from "primereact/checkbox";

const filters = {
	PN: [
		"Section",
		"Section en pouce",
		"Rapport d’aspect",
		"Diamètre d’accrochage",
		"Nombre de plys",
		"Profil"
	],
	CH: [
		"Section",
		"Diamètre d’accrochage en pouces",
		"Type de valve"
	],
	RC: [
		"Section",
		"Section en pouce",
		"Diamètre d’accrochage",
		"Rapport d’aspect",
		"Nombre de plys",
		"Profil",
		"Nb de trous",
		"Déport"
	],
	RN: [
		"Largeur",
		"Diamètre d'accrochage",
		"Nb de trous",
		"Déport"
	]
} as const;
export type FilterProps = {
	category: 'RN' | 'CH' | 'PN' | 'RC';
	products: Product[];
	showDiscounts?: boolean;
	onFilter: (products: Product[]) => void;
}
export const Filter: React.FC<FilterProps> = props => {

	const [appliedFilters, setAppliedFilters] = useState<Record<string, string | undefined>>({})
	const [filteredProducts, setFilteredProducts] = useState<Product[]>(props.products);
	const filteredFields = useMemo(() => filters[props.category], [props.category]);

	const onUpdateFilter = (field: string, v: string) => {
		setAppliedFilters(prev => produce(prev, draft => {
			if (v === undefined)
				delete draft[field];
			else
				draft[field] = v;
		}));
	}

	const [showDiscount, setShowDiscount] = useState<boolean>(false);


	const types = useMemo(() => {
		return Array.from(new Set(filteredProducts.filter(p => p.meta['Famille Divalto'].replace('EN', 'PN').trim() === props.category).map(p => p.type)));
	}, [filteredProducts])

	const brands = useMemo(() => {
		return Array.from(new Set(filteredProducts.filter(p => p.meta['Famille Divalto'].replace('EN', 'PN').trim() === props.category).map(p => p.brand)));
	}, [filteredProducts])

	const filterFunctions = useMemo(() => {
		return [...filteredFields, "type", "brand"].map((field: string) => {
			const filterProductByCriteria = (criteria?: string) => (product: Product) => {

				if (field === "type" || field === "brand") {
					return product[field] === criteria;
				}

				const fieldName = `${props.category}_M_${field}`;
				const fieldNameAlternative = `${props.category}_MT_${field}`;
				const fieldNameAlternative2 = `${props.category}_TM_${field}`;

				// @ts-ignore
				const result = product.meta[fieldName] ?? product.meta[fieldNameAlternative] ?? product.meta[fieldNameAlternative2];

				return result === criteria
			}
			return [field, filterProductByCriteria] as const;
		});
	}, [filteredFields, props.category])

	const fieldsValues = useMemo(() => {
		return filteredFields.map(field => {
			let filteredProductsByField = filteredProducts;
			for (const [k, f] of filterFunctions) {
				if (k in appliedFilters && k.trim() !== field) {
					filteredProductsByField = filteredProductsByField.filter(f(appliedFilters[k]))
				}
			}

			return [field, filteredProductsByField] as const;
		})
	}, [filteredFields, filteredProducts])

	const updateFilteredProducts = useCallback(() => {
		let initial: Product[];
		if (showDiscount) {
			initial = props.products.filter(e => e.hasDiscount && e.meta['Famille Divalto'].replace('EN', 'PN').trim() === props.category).sort(e => e.hasDiscount ? -1 : 1);
		} else {
			initial = props.products.filter(e => e.meta['Famille Divalto'].replace('EN', 'PN').trim() === props.category);
		}

		for (const [k, f] of filterFunctions) {
			if (k.trim() in appliedFilters) {
				initial = initial.filter(f(appliedFilters[k]))
			}
		}
		setFilteredProducts(initial);
	}, [appliedFilters, filterFunctions, showDiscount])

	useEffect(() => {
		updateFilteredProducts();
	}, [updateFilteredProducts]);

	useEffect(() => {
		setFilteredProducts(props.products);
	}, [props.products]);

	return (
		<div>
			<div className="Filter-section">
				<div className="Filter-section-title mb-3">
					Rechercher par taille :
				</div>
				<div className="Filter-section-input">
					{
						fieldsValues.map(([field, v]) => {

							const options = Array.from(new Set(v.map(e => {
								const fieldName = `${props.category}_M_${field}`;
								const fieldNameAlternative = `${props.category}_MT_${field}`;
								const fieldNameAlternative2 = `${props.category}_TM_${field}`;
								// @ts-ignore
								return e.meta[fieldName] ?? e.meta[fieldNameAlternative] ?? e.meta[fieldNameAlternative2];
							}))).filter(e => e.length > 0).map(e => ({label: e, value: e}));

							options.sort(({value: a}, {value: b}) => {

								const [an, bn] = [+a, +b];

								if (isNaN(an) && isNaN(bn)) {
									return a < b ? -1 : 1;
								}

								return an - bn;
							})

							return <div
								className="Filter-section-input-line flex justify-content-between align-items-center"
								key={field}>
								<div className="Filter-section-input-line-text">{field}:</div>
								<Dropdown appendTo={"self"}
								          className={classNames([field in appliedFilters && 'p-dropdown-activated'])}
								          disabled={options.length === 0} showClear
								          onChange={e => onUpdateFilter(field, e.value)} value={appliedFilters[field]}
								          options={options} optionValue="value" optionLabel="label"/>
							</div>
						})
					}
				</div>
			</div>
			<div className="Filter-section mb-4">
				<div className="Filter-section-title mb-4">
					Filtrer la recherche
				</div>
				<div className="Filter-section-input">
					<div className="Filter-section-input-line">
						<Dropdown placeholder="Catégorie" showClear className="p-dropdown-variant w-full"
						          options={types} appendTo="self" onChange={e => onUpdateFilter('type', e.value)}
						          value={appliedFilters['type']}/>
					</div>
					<div className="Filter-section-input-line">
						<Dropdown placeholder="Marque" showClear className="p-dropdown-variant w-full" options={brands}
						          appendTo="self" onChange={e => onUpdateFilter('brand', e.value)}
						          value={appliedFilters['brand']}/>
					</div>
					{(props.showDiscounts === undefined || props.showDiscounts) && <div className="Filter-section-input-line">
						<Checkbox checked={showDiscount} onChange={(e) => setShowDiscount(e.checked)}/>
						<label className="Filter-section-input-line-checkbox-label"> Afficher uniquement les
							promotions</label>
					</div>}
				</div>
			</div>
			<div className="Filter-section">
				<Button label={`Afficher les ${filteredProducts.length} résultats`.toUpperCase()} className="w-full" onClick={() => { props.onFilter(filteredProducts); }}/>
			</div>
		</div>)
}
