import React, {useContext, useEffect, useState} from "react";
import {Button} from "primereact/button";
import './Catalog.scss';
import {Product} from "@Types/Product";
import {CatalogItem} from "@Components/CatalogItem";
import {CustomDropdown} from "@Components/CustomDropdown";
import {LoadingDiv} from "@Components/LoadingDiv";
import {Filter} from "@Pages/Home/Catalog/Filter";
import { Search } from "./Catalog/Search";
import {useNavigate} from "react-router-dom";
import {CatalogContext} from "../../Contexts/CatalogContext";
import produce from "immer";
import {PageHeaderContext} from "@Contexts/PageHeaderProvider";
import {CartContext} from "@Contexts/CartProvider";
import {useNetworkState} from "react-use";
import {SwippableModal} from "@Components/SwippableModal";
import {FirebaseContext} from "@Contexts/FirebaseContext";
import { logEvent } from "firebase/analytics";
import {useUser} from "@Hooks/firebase";

const dropdownOptions = [{
    label: "PNEUS",
    value: "EN"
}, {
    label: "ROUES NUES",
    value: "RN"
}, {
    label: "CHAMBRE À AIR",
    value: "CH"
}, {
    label: "ROUES COMPLÈTES",
    value: 'RC'
}] as const;

export type CatalogProps = Record<string, never>
export const Catalog: React.FC<CatalogProps> = () => {

    const [category, setCategory] = useState<'EN' | 'RN' | 'CH' | 'RC'>(dropdownOptions[0].value);

    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

    const { online } = useNetworkState();
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [searchVisible, setSearchVisible] = useState<boolean>(false);

    const fb = useContext(FirebaseContext);
    const user = useUser(true);
    const navigate = useNavigate();

    const {products, isLoading, loadProducts} = useContext(CatalogContext);
    const {setHeader} = useContext(PageHeaderContext);
    const {cart} = useContext(CartContext);

    useEffect(() => {
        setFilteredProducts(products.filter(p => p.meta["Famille Divalto"] === category))
    }, [products, category]);

    useEffect(() => {
        if (!isLoading && online && products.length === 0) {
            loadProducts();
        }
    }, [isLoading, products]);

    useEffect(() => {
        setHeader(produce(prev => {
            prev.right = {
                icon: 'pi pi-shopping-cart',
                badge: cart.length > 0 ? cart.length.toString() : undefined,
                action: () => {
                    navigate("/home/cart");
                }
            }
            return prev;
        }))
        console.log('catalog');
    }, []);

    return (
        <div className="Catalog p-4 w-full flex-1">
	        <SwippableModal visible={filterVisible} onHide={() => setFilterVisible(false)}>
		        <Filter category={category === "EN" ? "PN" : category} products={products} onFilter={(products) => {
					setFilteredProducts(products);
					setFilterVisible(false);
		        }} />
	        </SwippableModal>
            <Search visible={searchVisible} setVisible={setSearchVisible} products={products} onFilter={(reference) => {
                if (fb?.analytics && user.meta) {
                    logEvent(fb.analytics, "search_ref", {
                        reference,
                        user_id: user.meta.userUid
                    });
                }
                navigate(`/home/${reference}`);
            }}/>
            <div className="Catalog__filter flex justify-content-between">
                <CustomDropdown value={category} onChange={e => setCategory(e)} options={dropdownOptions as any} className="h-full"/>
                <Button icon={"pi pi-filter"} className={"h-full p-button-rounded"} style={{width: 44}} onClick={() => setFilterVisible(true)}/>
            </div>
            <LoadingDiv loading={isLoading && products.length === 0} className="Catalog__content overflow-auto">
                {
                    filteredProducts.map(e => <CatalogItem product={e} key={e.reference} onClick={(product) => {navigate(`/home/${product.reference}`, {
                        state: product
                    })}}/> )
                }
            </LoadingDiv>
            <div className="fps--card-variant w-full flex justify-content-center align-items-center fixed left-0 bottom-0 p-5" style={{background:"white"}}>
                <Button label="Rechercher par référence" onClick={() => setSearchVisible(true)} className="w-full"/>
            </div>
        </div>
    )
}
