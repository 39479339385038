import React from "react";
import {User} from "@Types/User";
import {Avatar} from "primereact/avatar";
import './UserBadge.scss';
export type UserBadgeProps = {
    user: User
}
export const UserBadge: React.FC<UserBadgeProps> = props => {

    return (
        <div className="UserBadge flex">
            <div className="mr-2">
                <Avatar icon='pi pi-user' size="large" shape="circle" style={{height: "50px"}} className="UserBadge-avatar"/>
            </div>
            <div className="flex-1 flex flex-column justify-content-start align-items-start" style={{padding: "5px"}}>
                <div className="UserBadge-name">{props.user.firstname} {props.user.lastname}</div>
                <div className="UserBadge-email mt-auto">{props.user.email}</div>
            </div>
        </div>
    )
}
