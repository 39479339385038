import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Quotation} from "@Types/Quotation";
import {InputText} from "primereact/inputtext";
import {useFormik} from "formik";
import {QuotationSchema} from "@Schemas/quotation.schema";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {QuotationContext} from "@Contexts/QuotationContext";
import {Dropdown} from "primereact/dropdown";
import {HiddableDiv} from "@Components/HiddableDiv";
import {Button} from "primereact/button";
import {calculateTotalWithMargin, numberWithSpaces} from "@Utils/price.utils";
import {PageHeaderContext} from "@Contexts/PageHeaderProvider";
import produce from "immer";

export type QuotationValidateProps = {
}
export const QuotationValidate: React.FC<QuotationValidateProps> = (props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const {quotations} = useContext(QuotationContext);
    const {quotationUid} = useParams<{quotationUid?: string}>();

    const [quotation, setQuotation] = useState<Quotation | null>(null );
    const Api = useApi();
    const {setHeader} = useContext(PageHeaderContext);
    const {success, promise} = useToast();

    useEffect(() => {
        if (location.state) {
            setQuotation(location.state as Quotation);
        } else {
            const foundQuotation = quotations.find(o => o.quotationUid === quotationUid);
            if (foundQuotation)
                setQuotation(foundQuotation);
        }
    }, [location, quotationUid, quotations]);

    const initialValues = useMemo<Quotation>(() => {
        return {
            client: {
                phone: quotation?.client.phone || '',
                name: quotation?.client.name ||  '',
                mail: quotation?.client.mail || ''
            },
            items: quotation?.items || [],
            created_at: new Date().toISOString(),
            quotationUid: quotationUid || '',
            extra_charge: quotation?.extra_charge,
            reminder_delay: quotation?.reminder_delay || undefined,
            validated: false,
        }
    }, [quotation])

    const totalHT = useMemo(() => {
        if (!quotation) return 0;
        return calculateTotalWithMargin(quotation.items, quotation.extra_charge?.price ?? 0)
    }, [quotation])

    const onSave = (values: Quotation) => {
        return Api.quotation_call_updateQuotation({
            quotation: values
        }).then(res => {
            if (res.result === 'ok') {
                return;
            }
			throw new Error(res.result);
        })
    }

    const quotationFormik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: QuotationSchema,
        onSubmit: onSave,
        validateOnMount: true,
    });

    const onSendQuotation = () => {
        if (quotationUid) {
            setLoading(true);
            onSave(quotationFormik.values).then(() => {
                Api.quotation_call_sendQuotation({
                    quotationUid
                }).then(res => {
                    if (res.result === 'ok') {
                        success('Mail envoyé !');
                        navigate('/home')
                    }
                }).finally(() => {
                    setLoading(false);
                })
            }).catch(() => {
                setLoading(false);
            })
        }
    }


    useEffect(() => {
        setHeader(prev => {
            return {
                ...prev,
                right: quotationFormik.isValid ? {
                    icon: 'pi pi-save',
                    action: () => {
						promise(quotationFormik.submitForm(),{
							success: 'Informations enregistrées',
							pending: 'Enregistrement en cours',
							error: 'Un erreur est survenue lors de la sauvegarde'
						})

                    }
                } : {}
            }
        })
    }, [quotationFormik.isValid]);


    const handleChange = (name: string, value: any) => {
        quotationFormik.setFieldValue(name, value, true);
    }

    const navigate = useNavigate();

    const [inputFocused, setInputFocused] = useState<boolean>(false);


    const onInputFocus = () => {
        setInputFocused(true);
    };

    const onInputBlur = () => {
        setInputFocused(false);
    }
    return (
        <div className="QuotationValidate px-4 pt-4" style={{paddingBottom: '232px'}}>
            <div className="Order-title">
                Validation devis
            </div>
            <div className="Order-form mt-4">
                <div className="Order-form-title">
                    Informations du client
                </div>
                <div className="Order-form-input mt-3">
                    <label className="mb-2" htmlFor="name">Nom du client</label>
                    <InputText
                        placeholder="Saisir un nom"
                        name="name"
                        id="name"
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        value={quotationFormik.values.client.name}
                        onChange={(e) => handleChange('client.name', e.currentTarget.value)}
                    />
                </div>
                <div className="Order-form-input mt-3">
                    <label className="mb-2" htmlFor="phone">Numéro de téléphone</label>
                    <InputText
                        placeholder="Saisir un numéro de téléphone"
                        id="phone"
                        name="phone"
                        value={quotationFormik.values.client.phone}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        onChange={(e) => handleChange('client.phone', e.currentTarget.value)}
                    />
                </div>
                 <div className="Order-form-input mt-3">
                    <label className="mb-2" htmlFor="email">E-mail</label>
                    <InputText
                        placeholder="Saisir une adresse e-mail"
                        id="email"
                        name="email"
                        value={quotationFormik.values.client.mail}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        onChange={(e) => handleChange('client.mail', e.currentTarget.value)}
                    />
                 </div>
                 <div className="Order-form-input mt-3">
                    <label className="mb-2" htmlFor="delay">Programmer relance (optionnel)</label>
                    <Dropdown
                        placeholder="Choisir un délai"
                        id="delay"
                        name="delay"
                        className="w-full"
                        options={[{
                            label: '28 jours',
                            value: 28,
                        },{
                            label: '21 jours',
                            value: 21
                        },  {
                            label: '14 jours',
                            value: 14
                        }, {
                            label: '7 jours',
                            value: 7
                        }]}
                        showClear
                        value={quotationFormik.values.reminder_delay}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        onChange={(e) => handleChange('reminder_delay', e.value)}
                    />
                 </div>
            </div>
            <HiddableDiv hidden={inputFocused} className="z-2 fps--card-variant w-full flex flex-column justify-content-center align-items-center fixed left-0 bottom-0 p-4" style={{background:"white"}}>
                <div className="flex justify-content-between align-items-center w-full mb-3">
                    <div className="fps__text--small">Total prix de vente HT :</div>
                    <div className="fps__text--medium-bold">{numberWithSpaces(totalHT)} €</div>
                </div>
                <Button disabled={!quotationFormik.isValid || loading} loading={loading} label="ENVOYER LE DEVIS" onClick={onSendQuotation} className="w-full"/>
                <Button disabled={loading} label="RETOUR À L'ÉTAPE PRÉCÉDENTE" onClick={() => navigate(quotation ? `/home/my-quotations/${quotation.quotationUid}` : '/home/my-quotations')} className="w-full fps__button--secondary-no-border pb-0"/>
            </HiddableDiv>
        </div>
    )
}
