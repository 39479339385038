import React, {useContext, useEffect, useMemo, useState} from "react";
import {Button} from "primereact/button";
import './PublicCatalog.scss';
import {Product} from "@Types/Product";
import {CustomDropdown} from "@Components/CustomDropdown";
import {LoadingDiv} from "@Components/LoadingDiv";
import {Filter} from "@Pages/Home/Catalog/Filter";
import produce from "immer";
import {PageHeaderContext} from "@Contexts/PageHeaderProvider";
import {CartContext} from "@Contexts/CartProvider";
import {useNetworkState} from "react-use";
import {PublicCatalogContext} from "@Contexts/PublicCatalogContext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {numberWithSpaces} from "@Utils/price.utils";
import Fuse from "fuse.js";
import {Dialog} from "primereact/dialog";
import {ProductTechnicalInformations} from "@Components/ProductTechnicalInformations";

const dropdownOptions = [{
    label: "PNEUS",
    value: "EN"
}, {
    label: "ROUES NUES",
    value: "RN"
}, {
    label: "CHAMBRE À AIR",
    value: "CH"
}, {
    label: "ROUES COMPLÈTES",
    value: 'RC'
}] as const;

export type PublicCatalogProps = Record<string, never>
export const PublicCatalog: React.FC<PublicCatalogProps> = () => {

    const [category, setCategory] = useState<'EN' | 'RN' | 'CH' | 'RC'>(dropdownOptions[0].value);

    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
	const [search, setSearch] = useState<string>('');

	const [selectedProduct, setSelectedProduct] = useState<Product>();

	const filteredBySearch = useMemo(() => {
		const fuse = new Fuse(filteredProducts.map(produce((product: Product) => {
			// @ts-ignore
			product.filterDes = product.des.replaceAll(/\D/g, '');
			return product;
		})), {
			isCaseSensitive: false,
			shouldSort: true,
			distance: 0,
			threshold: 0.15,
			includeScore: false,
			keys: ['reference', 'filterDes']
		});

		const resultOfSearch = fuse.search(search);

		return search.length > 0 ? resultOfSearch.map(e => e.item) : filteredProducts;

	}, [filteredProducts, search])

    const { online } = useNetworkState();
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
	const [productVisible, setProductVisible] = useState<boolean>(false);


	const {products, isLoading, loadProducts} = useContext(PublicCatalogContext);
	const {setHeader} = useContext(PageHeaderContext);
	const {cart} = useContext(CartContext);

	useEffect(() => {
		setFilteredProducts(products.filter(p => p.meta["Famille Divalto"] === category))
	}, [products, category]);

	useEffect(() => {
		if (!isLoading && online && products.length === 0) {
			loadProducts();
		}
	}, [isLoading, products]);

	useEffect(() => {
		setHeader(produce(prev => {
			prev.right = {};
			prev.left =  {};
			return prev;
		}))
		console.log('catalog');
	}, []);

	const onSearch = (searchValue: string) => {
		setSearch(searchValue);
	}

	return (
		<div className="PublicCatalog h-full p-4 flex-1 mx-auto">
			<Dialog dismissableMask visible={filterVisible} onHide={() => setFilterVisible(false)} style={{width: "600px"}} contentClassName="px-4 pt-4 pb-3" headerClassName="px-4 pb-0 pt-4">
				<Filter showDiscounts={false} category={category === "EN" ? "PN" : category} products={products} onFilter={(products) => {
					setFilteredProducts(products);
					setFilterVisible(false);
				}} />
			</Dialog>
			<Dialog dismissableMask visible={productVisible} onHide={() => {
				setProductVisible(false);
				setSelectedProduct(undefined);
			}} style={{width: "600px"}} contentClassName="px-4 pt-4 pb-3" headerClassName="px-4 pb-0 pt-4">
				{selectedProduct && <ProductTechnicalInformations product={selectedProduct} />}
			</Dialog>
			<div className="PublicCatalog__filter flex justify-content-between mt-2">
	            <span className="p-input-icon-left">
		            <i className="pi pi-search" />
					<InputText keyfilter="int" placeholder="Référence ou désignation (ex: 105001 / 56050225)" onChange={e => onSearch(e.target.value)}/>
				</span>
                <CustomDropdown value={category} onChange={e => setCategory(e)} options={dropdownOptions as any} className="ml-auto mr-4 h-full"/>
                <Button icon={"pi pi-filter"} className={"h-full p-button-rounded"} style={{width: 39}} onClick={() => setFilterVisible(true)}/>
            </div>
            <LoadingDiv loading={isLoading && products.length === 0} className="PublicCatalog__content pt-4">
                <DataTable value={filteredBySearch} removableSort autoLayout scrollable scrollHeight={"680px"} rowHover emptyMessage="Aucun résultat trouvé" onRowClick={(event: any) => {
					setProductVisible(true);
					setSelectedProduct(event.data);
                }}>
	                {/*<Column body="body" header="&nbsp;" headerStyle={{width: "2rem"}}/>*/}
	                <Column field="brand" header="Marque" filterField="brand" body={(data: any) => <img src={`/assets/images/${data.brand.trim().toLowerCase()}-logo-mini.png`} style={{height: "14px"}}/>} />
	                <Column field="type" header="Catégorie" bodyClassName="category"/>
	                <Column field="reference" header="Référence" className="small-text"/>
	                <Column field="des" header="Désignation" className="small-text"/>
	                <Column field="price" header="Prix BF" body={(data: any) => numberWithSpaces(data.price) + " €"} align="right" alignHeader="right" headerClassName="price-header" />
                </DataTable>
            </LoadingDiv>
        </div>
    )
}
