import {useCallback, useContext, useState} from "react";
import {SwippableModal} from "@Components/SwippableModal";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import { useApi } from "@Hooks/api";
import {Quotation} from "@Types/Quotation";
import produce from "immer";
import { logEvent } from "firebase/analytics";
import { FirebaseContext } from "@Contexts/FirebaseContext";
import { useUser } from "@Hooks/firebase";

export type QuotationSaveModalProps = {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    mode: 'save' | 'validate',
    quotation: Quotation,
    onFinished: (quotation: Quotation, isValid?: boolean) => void,
    onError?: (err: Error) => void
}
export const QuotationSaveModal: React.FC<QuotationSaveModalProps> = props => {

    const [name, setName] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fb = useContext(FirebaseContext);
    const user = useUser(true);

    const Api = useApi();

    const onSave = useCallback(() => {
        setIsLoading(true);
        Api.quotation_call_createQuotation({
            quotation: produce(props.quotation, draft => {
                draft.client.name = name;
                return draft;
            })
        }).then(res => {
            if (res.result === 'ok') {
                if (fb && fb.analytics && user.meta) {
                    logEvent(fb.analytics, "quotation_created", {
                        user_id: user.meta.userUid
                    })
                }
                if (props.mode === 'validate') {
                    Api.quotation_call_validateQuotation({
                        quotationUid: res.quotation.quotationUid
                    }).then(validateRes => {
                        if (validateRes.result === 'ok') {
                            props.onFinished(res.quotation, true);
                            props.setVisible(false);
                        }
                    }).catch(err => {
                        props.onError?.(err);
                    }).finally(() => {
                        setIsLoading(false);
                        props.setVisible(false);
                    })
                } else {
                    setIsLoading(false);
                    props.setVisible(false);
                    props.onFinished(res.quotation);
                }
            }
        }).catch((err) => {
            setIsLoading(false);
            props.onError?.(err)
        })
    }, [name, props.mode, props.quotation, fb, user.meta]);

    const onCancel = () => props.setVisible(false)

    return (
        <SwippableModal visible={props.visible} onHide={() => props.setVisible(false)}>
            <div className="QuotationExtraModal w-full h-auto">
                <div className="fps__text--big mb-4">
                    {props.mode === "save" ? 'SAUVEGARDER LE DEVIS' : 'VALIDER LE DEVIS'}
                </div>
                {props.mode === "validate" && <div className="fps__text--small-gray text-left mb-3">
                    En cliquant sur valider le devis vous ne pourrez plus editer les informations.
                </div>}
                <div>
                    <InputText className="w-full mb-3" placeholder="Saisir nom du client" value={name} onChange={e => setName(e.target.value)}/>
                    <span className="p-input-icon-left w-full">
                </span>
                </div>
                <Button disabled={!name} loading={isLoading} label={props.mode === "save" ? "SAUVEGARDER LE DEVIS" : "JE CONFIRME"} className="w-full" onClick={onSave}/>
                {props.mode === "validate" && <Button label="REVENIR SUR LE DEVIS" className="w-full fps__button--secondary mt-3" onClick={onCancel}/>}
            </div>
        </SwippableModal>
    )
}
