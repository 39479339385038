import React, {PropsWithChildren, useEffect, useState} from "react";
import {Product} from "@Types/Product";
import {useApi} from "@Hooks/api";
import {usePromise} from "@Hooks/promise";
import {useUser} from "@Hooks/firebase";
import {useNetworkState} from "react-use";

export type ICatalogContext = {
    products: Product[];
    setProducts: (products: Product[]) => void;
    isLoading: boolean;
    loadProducts: () => void;
}

export const CatalogContext = React.createContext<ICatalogContext>({
    loadProducts: () => void 0,
    setProducts: () => void 0,
    isLoading: false,
    products: []
});

export const CatalogProvider: React.FC<PropsWithChildren<{}>> = (props) => {

    const user = useUser(true)

    const [products, setProducts] = useState<Product[]>([]);
    const Api = useApi();

    const {online, previous} = useNetworkState();

    const [promise, getPromiseMap] = usePromise(async () => {
        return Api.product_call_getProducts({}).then(result => {
            if (result.result === "ok") {
                setProducts(result.products)
            }
        }).catch();
    });


    useEffect(() => {
        if (!previous && online) {
            promise('product_loading')()
        }
    }, [online, previous]);

    useEffect(() => {
        promise('product_loading')()
    }, [user.meta.selectedAgency]);

    return (
        <CatalogContext.Provider value={{
            isLoading: !!getPromiseMap.get('product_loading'),
            products,
            setProducts,
            loadProducts: promise('product_loading')
        }} children={props.children}/>
    )
}
