import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Quotation, QuotationExtra, QuotationItem} from "@Types/Quotation";
import {QuotationContext} from "@Contexts/QuotationContext";
import {CatalogItem} from "@Components/CatalogItem";
import {QuotationMargin} from "@Pages/Home/Quotation/QuotationMargin";
import produce from "immer";
import {calculateTotalWithMargin, numberWithSpaces} from "@Utils/price.utils";
import {Button} from "primereact/button";
import {QuotationDetailDialog} from "@Pages/Home/Quotation/QuotationDetailDialog";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {PageHeaderContext} from "@Contexts/PageHeaderProvider";
import { QuotationExtraModal } from "./Quotation/QuotationExtraModal";
import {DateTime} from "luxon";
import {OrderContext} from "@Contexts/OrderContext";
import {useUser} from "@Hooks/firebase";
import { FirebaseContext } from "@Contexts/FirebaseContext";
import { logEvent } from "firebase/analytics";

export type QuotationReviewProps = Record<string, never>
export const QuotationReview: React.FC<QuotationReviewProps> = () => {

    const {quotations} = useContext(QuotationContext);
	const {setHeader} = useContext(PageHeaderContext);
	const {setTmpOrder} = useContext(OrderContext);
	const {meta} = useUser(true);
    const {quotationUid} = useParams<{ quotationUid?: string }>();

    const {success} = useToast();

	const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
    const [quotation, setQuotation] = useState<Quotation | null>(null);
    const [detailDialogVisible, setDetailDialogVisible] = useState<boolean>(false);
    const [extraModalVisible, setExtraModalVisible] = useState<boolean>(false);

	const navigate = useNavigate();
	const Api = useApi();

    const fb = useContext(FirebaseContext);
    const user = useUser(true);

	const isExpired = useMemo(() => {
		if (!quotation) return true;
		const dayDiff = DateTime.fromISO(quotation.created_at).plus({day: 30}).diffNow('days').days;
		return dayDiff <= 0;
	}, [quotation])

    const totalHT = useMemo<number>(() => {
        if (!quotation) return 0;
        return calculateTotalWithMargin(quotation.items, quotation.extra_charge?.price)
    }, [quotation])

    const loadQuotation = useCallback(() => {
        const foundQuotation = quotations.find(o => o.quotationUid === quotationUid);
        if (foundQuotation)
            setQuotation(foundQuotation);
    }, [quotationUid, quotations])

    useEffect(() => {
        loadQuotation();
    }, [loadQuotation]);

    const onSave = (values: Quotation) => {
        return Api.quotation_call_updateQuotation({
            quotation: values
        }).then(res => {
            if (res.result === 'ok') {
                success('Modifications enregistrées !')
            }
            return res;
        })
    }
    useEffect(() => {
        setHeader(prev => ({
            ...prev,
            right: !isExpired ? {
                icon: 'pi pi-save',
                action: () => {
                    if (quotation)
                        onSave(quotation)
                }
            } : {}
        }))
    }, [quotation]);

    const onSaveItemMargin = useCallback((item: QuotationItem, margin: number) => {
        setQuotation(produce(prev => {
            if (prev) {
                prev.items = prev.items.map(e => {
                    if (e.product.reference === item.product.reference) {
                        e.margin = margin;
                    }
                    return e;
                })
            }
        }))
    }, [quotation])

    const onOrder = () => {
		if (quotation) {
			setTmpOrder({
				shipping_details: {
					name: quotation.client.name,
					location: '',
					pickup: false,
					phone: quotation.client.phone || '',
					reference: ''
				},
				items: quotation?.items.map(i => {
					return {
						quantity: i.quantity,
						product: i.product
					}
				}),
				archived: false,
				orderUid: '',
				createdAt:'',
				client: meta,
				status: false,
				quotationUid: quotation.quotationUid
			})
			navigate('/home/order')
		}
    }

    const onEditClient = useCallback(() => {
        if (quotation)
            navigate(`/home/my-quotations/${quotation.quotationUid}/edit`)
    }, [quotation])

    const onValidate = useCallback(() => {
        if (quotation) {
            setLoadingValidation(true);
            onSave(quotation).then(save => {
                if (save?.result === 'ok') {

                    Api.quotation_call_validateQuotation({
                        quotationUid: quotation.quotationUid
                    }).then(res => {
                        if (fb && fb.analytics && user.meta) {
                            logEvent(fb.analytics, "quotation_created", {
                                user_id: user.meta.userUid,
                            });
                        }
                        success('Devis validé !');
                        setLoadingValidation(false);
                        navigate('/home/my-quotations')
                    }).catch(() => {
                        setLoadingValidation(false);
                    })
                }
            }).catch(() => {
                setLoadingValidation(false);
            })
        }
    }, [quotation])

    const onShowDetails = () => {
        setDetailDialogVisible(true)
    }

	const hasMargin = useMemo(() => {
		return quotation?.items.every(item => item.margin > 0)
	}, [quotation])

    return (
        quotation && <div className="Quotation">
            <QuotationDetailDialog visible={detailDialogVisible} setVisible={setDetailDialogVisible}
                                   quotation={quotation}/>
            <QuotationExtraModal
                visible={extraModalVisible}
                totalHT={totalHT}
                setVisible={setExtraModalVisible}
                extra={quotation.extra_charge}
                setExtra={(extra: QuotationExtra | undefined) => {
                    setQuotation(produce(draft => {
                        if (draft)
                            draft.extra_charge = extra;
                        return draft;
                    }))
                }}/>
            <div className="Quotation-title">
                DEVIS
            </div>
            <div className="Quotation-products mt-3">
                {quotation!.items.map(item => (<div key={item.product.reference}>
                        <CatalogItem showAvailability={false} key={item.product.reference} product={item.product}
                                     quantity={item.quantity}/>
                        <QuotationMargin locked={quotation.validated} product={item.product} quantity={item.quantity}
                                         margin={item.margin}
                                         onSave={margin => onSaveItemMargin(item, margin)}/>
                    </div>
                ))}
                <div className="Quotation-products-extra mt-4">
                {
                    !quotation.extra_charge ? !quotation.validated && <span className="block w-full" onClick={() => setExtraModalVisible(true)}>+ prestation supplémentaire</span> :
                        <div style={{opacity: quotation.validated ? '40%' : '100%'}}>
                            <div className="Quotation-products-extra-label mb-2">
                                {quotation.extra_charge.label}
                            </div>
                            <div
                                className="Quotation-products-extra-input flex justify-content-start align-items-center flex-grow-1 bg-white px-3 py-2"
                                onClick={() => !quotation.validated && setExtraModalVisible(true)}>
                                <i className="pi pi-euro mr-2"/>
                                <span>{numberWithSpaces(quotation.extra_charge.price)}</span>
                            </div>
                        </div>
                }
            </div>
            </div>

            <div
                className="fps--card-variant w-full flex flex-column justify-content-center align-items-center fixed left-0 bottom-0 p-4"
                style={{background: "white"}}>
                <div className="flex justify-content-between align-items-center w-full mb-3">
                    <div className="fps__text--small">Total prix de vente HT</div>
                    <div className="fps__text--medium-bold">{numberWithSpaces(totalHT)} €</div>
                </div>
                <div className="Quotation-card-details mb-3 w-full flex align-items-center" onClick={onShowDetails}>
                    <i className="pi pi-info-circle mr-2"/>
                    <span> Voir le détails du prix</span>
                </div>
                <Button disabled={isExpired || !hasMargin} loading={loadingValidation}
                        label={quotation.validated ? "EDITER LES INFORMATIONS CLIENT" : "VALIDER LE DEVIS"}
                        className="w-full" onClick={quotation.validated ? onEditClient : onValidate}/>
                <Button disabled={isExpired} label="PASSER LA COMMANDE" className="w-full fps__button--secondary-no-border mt-2"
                        onClick={onOrder}/>
            </div>
        </div>
    )
}
