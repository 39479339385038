import React, {useCallback, useState} from "react";
import './MyAgencies.scss';
import {useUser} from "@Hooks/firebase";
import {Button} from "primereact/button";
import {SwippableModal} from "@Components/SwippableModal";
import {InputText} from "primereact/inputtext";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {usePromise} from "@Hooks/promise";

export type MyAgenciesProps = Record<string, never>
export const MyAgencies: React.FC<MyAgenciesProps> = () => {

    const {meta} = useUser(true);
    const Api = useApi();

    const [visible, setVisible] = useState<boolean>(false);

    const [code, setCode] = useState<string>('');

    const {success} = useToast();

    function onShowAdd() {
        setVisible(true);
    }

    const onAddAgency = useCallback(() => {
        return Api.agencyrequest_call_createAgencyRequest({
            agencyRequest: {
                agency_code: code,
                agencyRequestUid: '',
                createdAt: ''
            }
        }).then(res => {
            if (res.result === "ok") {
                setVisible(false);
                success('Votre demande a bien été envoyée !');
            }
        }).catch(err => {
            console.log(err);
        })
    }, [code])

    const [createAgency, createAgencyMap] = usePromise(onAddAgency)

    return (
        <div className="MyAgencies">
            <div className={'MyAgencies-subtitle mb-4'}>
                Mes agences
            </div>
            {meta.agencies.map(e => {
                return <div key={e.code} className="MyAgencies-item mb-2">
                    <div className="MyAgencies-item-name">{e.name}</div>
                    <div className="MyAgencies-item-address">{e.address}</div>
                    <div className="MyAgencies-item-code">Code client: {e.code}</div>
                </div>
            })}
            <div className="fixed bottom-0 px-5 py-4 bg-white w-full">
                <Button label="Ajouter une agence" icon="pi pi-plus" onClick={onShowAdd} className="w-full"/>
            </div>
            <SwippableModal visible={visible} onHide={() => setVisible(false)}>
                <div className="fps__text--big">
                    Ajouter une agence
                </div>
                <div className="mt-4" style={{marginBottom: 50}}>
                    <InputText placeholder={"Saisir le code client de l'agence"} className={"w-full"} value={code} onChange={e => setCode(e.target.value)}/>
                </div>
                <div>
                    <Button loading={createAgencyMap.get('create')} label={"Ajouter l'agence"} className="w-full" onClick={createAgency('create')}/>
                </div>
            </SwippableModal>
        </div>
    )
}
