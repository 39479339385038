import React from 'react';
import {createRoot} from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import {App} from "./App";
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {enableMapSet} from "immer";
import {FirebaseProvider} from "./Contexts/FirebaseContext";
import 'normalize.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import './theme/theme.scss';
import "primereact/resources/primereact.min.css"; //core css
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import LogRocket from "logrocket";

LogRocket.init('fps-bah8j/fps');
enableMapSet();

/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
    // @ts-ignore
    String.prototype.replaceAll = function(str: string, newStr: string){

        // If a regex pattern
        if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            return this.replace(str, newStr);
        }

        // If a string
        return this.replace(new RegExp(str, 'g'), newStr);

    };
}

const root = createRoot(document.getElementById('root')!);
root.render(
    <FirebaseProvider >
        <BrowserRouter>
            <div className="fps--background w-full h-full flex justify-content-center">
                <ToastContainer />
                <App />
            </div>
        </BrowserRouter>
    </FirebaseProvider>
)
serviceWorkerRegistration.register();
