import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {CatalogItem} from "@Components/CatalogItem";
import {Quotation as QuotationModel, QuotationExtra, QuotationItem} from "@Types/Quotation";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {QuotationMargin} from "@Pages/Home/Quotation/QuotationMargin";
import {PageHeaderContext} from "@Contexts/PageHeaderProvider";
import {QuotationExtraModal} from "@Pages/Home/Quotation/QuotationExtraModal";
import {calculateTotalWithMargin, numberWithSpaces} from "@Utils/price.utils";
import {Button} from "primereact/button";
import './Quotation.scss';
import {QuotationContext} from "@Contexts/QuotationContext";
import produce from "immer";
import {QuotationSaveModal} from "@Pages/Home/Quotation/QuotationSaveModal";
import {useToast} from "@Hooks/toast";
import {QuotationDetailDialog} from "@Pages/Home/Quotation/QuotationDetailDialog";
import {CartContext} from "@Contexts/CartProvider";

export type QuotationProps = Record<string, never>
export const Quotation: React.FC<QuotationProps> = () => {

    const headerContext = useContext(PageHeaderContext);

    const {tmpQuotation, setTmpQuotation} = useContext(QuotationContext);
    const {resetCart} = useContext(CartContext);

    const [extraModalVisible, setExtraModalVisible] = useState<boolean>(false);
    const [saveModalVisible, setSaveModalVisible] = useState<boolean>(false);
    const [validateModalVisible, setValidateModalVisible] = useState<boolean>(false);
    const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);

    const {success} = useToast();

    const location = useLocation();
    const navigate = useNavigate();

    const onSaveItemMargin = useCallback((item: QuotationItem, margin: number) => {
        setTmpQuotation(produce(prev => {
            if (prev) {
                prev.items = prev.items.map(e => {
                    if (e.product.reference === item.product.reference) {
                        e.margin = margin;
                    }
                    return e;
                })
            }
        }))
    }, [tmpQuotation])

    const totalHT = useMemo(() => {
        if (!tmpQuotation) return 0;
        return calculateTotalWithMargin(tmpQuotation.items, tmpQuotation.extra_charge?.price)
    }, [tmpQuotation]);

    const onSave = () => {
        setSaveModalVisible(true);
    }

    const onSaved = () => {
        success('Devis sauvegardé !');
        navigate(`/home/my-quotations`);
        resetCart();
    }

    const onValidate = () => {
        setValidateModalVisible(true);
    }

    const onValidated = (quotation: QuotationModel) => {
        success('Devis validé !');
        navigate(`/home/my-quotations/${quotation.quotationUid}/edit`);
        resetCart()
    }

    const onOrder = () => {
        navigate('/home/order')
    }

    const onShowDetails = () => {
        setDetailModalVisible(true)
    }

    useEffect(() => {
        headerContext.setHeader(prev => {
            return {
                ...prev,
                right: {
                    icon: 'pi pi-save',
                    action: onSave
                }
            }
        })
    }, [location]);

	const hasMargin = useMemo(() => {
		return tmpQuotation?.items.every(item => item.margin > 0)
	}, [tmpQuotation])

    return (
        tmpQuotation ? <div className="Quotation">
            <QuotationSaveModal
                visible={saveModalVisible}
                setVisible={setSaveModalVisible}
                mode={"save"}
                onFinished={onSaved}
                quotation={tmpQuotation}/>
            <QuotationSaveModal
                visible={validateModalVisible}
                setVisible={setValidateModalVisible}
                mode={"validate"}
                onFinished={onValidated}
                quotation={tmpQuotation}/>
            <QuotationDetailDialog visible={detailModalVisible} setVisible={setDetailModalVisible} quotation={tmpQuotation} />
            <QuotationExtraModal
                visible={extraModalVisible}
                totalHT={totalHT}
                setVisible={setExtraModalVisible}
                extra={tmpQuotation.extra_charge}
                setExtra={(extra: QuotationExtra | undefined) => {
                    setTmpQuotation(produce(draft => {
                        if (draft)
                            draft.extra_charge = extra;
                        return draft;
                    }))
                }}/>
            <div className="Quotation-title">
                DEVIS ({tmpQuotation?.items.length})
            </div>
            <div className="Quotation-products mt-3">
                {tmpQuotation!.items.map(item => (<div key={item.product.reference}>
                        <CatalogItem showAvailability={false} key={item.product.reference} product={item.product}
                                     quantity={item.quantity}/>
                        <QuotationMargin product={item.product} quantity={item.quantity} margin={item.margin}
                                         onSave={margin => onSaveItemMargin(item, margin)}/>
                    </div>
                ))}
                <div className="Quotation-products-extra mt-4">
                    {
                        !tmpQuotation.extra_charge ? <span className="block w-full" onClick={() => setExtraModalVisible(true)}>+ prestation supplémentaire</span> :
                            <div>
                                <div className="Quotation-products-extra-label mb-2">
                                    {tmpQuotation.extra_charge.label}
                                </div>
                                <div className="Quotation-products-extra-input flex justify-content-start align-items-center flex-grow-1 bg-white px-3 py-2" onClick={() => setExtraModalVisible(true)}> <i className="pi pi-euro mr-2"/>
                                    <span>{numberWithSpaces(tmpQuotation.extra_charge.price)}</span>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <div
                className="fps--card-variant w-full flex flex-column justify-content-center align-items-center fixed left-0 bottom-0 p-4"
                style={{background: "white"}}>
                <div className="flex justify-content-between align-items-center w-full mb-3">
                    <div className="fps__text--small">Total prix de vente HT</div>
                    <div className="fps__text--medium-bold">{numberWithSpaces(totalHT)} €</div>
                </div>
                <div className="Quotation-card-details mb-3 w-full flex align-items-center" onClick={onShowDetails}>
                    <i className="pi pi-info-circle mr-2"/>
                    <span> Voir le détails du prix</span>
                </div>
                <Button disabled={!hasMargin} label="VALIDER LE DEVIS" className="w-full" onClick={onValidate}/>
                <Button label="PASSER LA COMMANDE" className="w-full fps__button--secondary-no-border pb-0" onClick={onOrder}/>
            </div>
        </div> : <Navigate to={'/home'} />
    )
}
