import React, {useContext, useEffect} from "react";
import {DateTime} from "luxon";
import {QuotationContext} from "@Contexts/QuotationContext";
import {useNavigate} from "react-router-dom";
import {TabPanel, TabView} from "primereact/tabview";
import './MyQuotations.scss'
import {Quotation} from "@Types/Quotation";
import {Button} from "primereact/button";
import {useApi} from "@Hooks/api";
import {useToast} from "@Hooks/toast";
import {usePromise} from "@Hooks/promise";
import {PageHeaderContext} from "@Contexts/PageHeaderProvider";
import {CartContext} from "@Contexts/CartProvider";
import {calculateTotalWithMargin, numberWithSpaces} from "@Utils/price.utils";
export type MyQuotationsProps = Record<string, never>
export const MyQuotations: React.FC<MyQuotationsProps> = () => {

    const {quotations} = useContext(QuotationContext);
    const navigate = useNavigate();

    const Api = useApi();
    const {success} = useToast();

    const {setHeader} = useContext(PageHeaderContext);
    const {cart} = useContext(CartContext);

    const onCardClick = (quotation: Quotation) => {
        navigate(`/home/my-quotations/${quotation.quotationUid}`, {
            state: quotation
        });
    }

    async function onDeleteQuotation(quotation: Quotation) {
        return Api.quotation_call_deleteQuotation({quotationUid: quotation.quotationUid}).then(res => {
            if (res.result === 'ok') {
                success('Devis supprimé !');
            } else {
                throw new Error(res.result);
            }
        })
    }

    useEffect(() => {
        setHeader(prev => {
            return {
                ...prev,
                right: {
                    icon: 'pi pi-shopping-cart',
                    badge: cart.length > 0 ? cart.length.toString() : undefined,
                    action: () => {
                        navigate("/home/cart");
                    }
                }
            }
        })
    }, [cart]);

    const [deletePromise, deleteMap] = usePromise(onDeleteQuotation);

    return (
        <div className="MyQuotations py-4">
            <div className="MyQuotations-title uppercase px-4 pb-2">
                Mes Devis ({quotations.length})
            </div>
            <TabView className="mb-6">
                <TabPanel header="Devis validés">
                    {quotations.filter(q => q.validated).map(quotation => {

						const dayDiff = DateTime.fromISO(quotation.created_at!).plus({day: 30}).diffNow('days').days;

                        return <div key={quotation.quotationUid} className="MyQuotations-order flex justify-content-between p-4 bg-white mb-1" onClick={() => {
                            onCardClick(quotation)
                        }}>
                            <div className="flex flex-column">
                                <div className="MyQuotations-order-title mb-1">N°{quotation.quotationUid.substring(0,6).toUpperCase()} - {quotation.client.name}</div>
	                            {
									dayDiff > 0 ?
										<div className="MyQuotations-order-agency mb-1">Valide {dayDiff.toFixed(0)} jour(s) (créé {DateTime.fromISO(quotation.created_at).toFormat('dd/MM/yy')})</div> :
										<div className="MyQuotations-order-agency mb-1">Expiré</div>
	                            }
	                            {quotation.reminder_delay && <div className="MyQuotations-order-agency mb-1">Relance tous les {quotation.reminder_delay} jours</div>}
                                <div className="MyQuotations-order-price">{numberWithSpaces(calculateTotalWithMargin(quotation.items, quotation.extra_charge?.price || 0 ))} €</div>
                            </div>
                            <div className="flex justify-content-center align-items-center">
                                <Button loading={deleteMap.get(quotation.quotationUid)} icon={"pi pi-trash"} className={'p-button-rounded fps__button--secondary'} onClick={(e) => {
                                    e.stopPropagation();
                                    deletePromise(quotation.quotationUid)(quotation);
                                }}/>
                            </div>
                        </div>
                    })}
                </TabPanel>
                <TabPanel header="Devis en brouillon">
                    {quotations.filter(q => !q.validated).map(quotation => {
                        return <div key={quotation.quotationUid} className="MyQuotations-order flex justify-content-between p-4 bg-white mb-1" onClick={() => {
                            onCardClick(quotation)
                        }}>
                            <div className="flex flex-column">
                                <div className="MyQuotations-order-title mb-1">N°{quotation.quotationUid.substring(0,6).toUpperCase()} - {quotation.client.name}</div>
                                <div className="MyQuotations-order-agency mb-1">Créé le {DateTime.fromISO(quotation.created_at).toFormat('dd/MM/yy')}</div>
	                            {quotation.reminder_delay && <div className="MyQuotations-order-agency mb-1">Relance programmée tous les {quotation.reminder_delay} jour(s)</div>}
                                <div className="MyQuotations-order-price">{numberWithSpaces(calculateTotalWithMargin(quotation.items, quotation.extra_charge?.price || 0 ))} €</div>
                            </div>
                            <div className="flex justify-content-center align-items-center">
                                <Button loading={deleteMap.get(quotation.quotationUid)} icon={"pi pi-trash"} className={'p-button-rounded fps__button--secondary'} onClick={(e) => {
                                    e.stopPropagation();
                                    deletePromise(quotation.quotationUid)(quotation);
                                }}/>
                            </div>
                        </div>
                    })}
                </TabPanel>
            </TabView>
        </div>
    )
}
