import React, {useCallback, useEffect, useState} from "react";
import {Product} from "@Types/Product";
import {SwippableModal} from "@Components/SwippableModal";
import './QuotationMargin.scss';
import {
	calculateItemMargin,
	calculateMarginFromPercent,
	calculateMarginFromPrice,
	numberWithSpaces
} from "@Utils/price.utils";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {SelectItemOptionsType} from "primereact/selectitem";
import {InputNumber} from "primereact/inputnumber";
import {classNames} from "primereact/utils";

const options: SelectItemOptionsType = [{
    value: 'percent',
    label: 'Pourcent (%)'
}, {
    value: 'euro',
    label: 'Euro (€)'
}]

export type QuotationMarginProps = {
    product: Product,
    quantity: number,
    onSave: (margin: number) => void,
    margin?: number,
    locked?: boolean
}
export const QuotationMargin: React.FC<QuotationMarginProps> = props => {

    const [visible, setVisible] = useState<boolean>(false);

    const [type, setType] = useState<'percent' | 'euro'>('percent');
    const [margin, setMargin] = useState<number | null>(props.margin || null);
    const [calculatedMargin, setCalculatedMargin] = useState<number>(0);

    const updateMargin = useCallback((margin: number | null) => {
        setMargin(margin);
        switch (type) {
            case 'percent':
                if (margin)
                    setCalculatedMargin(calculateMarginFromPercent(props.product.price, margin / 100) - props.product.price);
                break;
            case 'euro':
                if (margin)
                    setCalculatedMargin(100 * calculateMarginFromPrice(props.product.price, margin));
                break;
        }
    }, [type]);

    const onChangeType = useCallback(() => {
        setMargin(prev => {
            setCalculatedMargin(prev || 0);
            return calculatedMargin;
        });
    }, [type, calculatedMargin])

    useEffect(() => {
        updateMargin(margin);
    }, [updateMargin, margin]);

    return (
        <div className="QuotationMargin">
            <SwippableModal visible={visible} onHide={() => setVisible(false)}>
                <div className="QuotationModal">
                    <div className="QuotationModal-title mb-4">
                        SAISIR LA MARGE
                    </div>
                    <div className="relative">
                        <InputNumber className="w-full" mode="decimal" minFractionDigits={2} value={margin} onChange={e => updateMargin(e.value)} placeholder="Saisir la marge"/>
                        <Dropdown options={options} defaultValue={"percent"} value={type}
                                  onChange={e => {
                                      setType(e.value);
                                      onChangeType();
                                  }} className="QuotationModal-input-dropdown absolute"
                                  style={{height: "36px", right: 8, top: 7}} appendTo="self"/>
                    </div>
                    <div className="my-4">
                        <div className="flex justify-content-between align-items-center">
                            <div> Prix d'achat unitaire :</div>
                            <div> {numberWithSpaces(props.product.price)} €</div>
                        </div>
	                    {
							margin && <>
                                <div className="flex justify-content-between align-items-center my-2">
                                    <div> Marge :</div>
                                    <div> {numberWithSpaces(calculatedMargin)} {type === "percent" ? "€" : "%"}</div>
                                </div>
                                <div className="flex justify-content-between align-items-center">
                                    <div> Prix de vente unitaire :</div>
                                    <div> {numberWithSpaces(type === 'percent' ? calculateMarginFromPercent(props.product.price, margin / 100) : props.product.price + margin)  } €</div>
                                </div>
							</>
	                    }
                    </div>
                    <Button label="VALIDER LA MARGE" className="w-full my-4" onClick={() => {
                        if (margin) {
                            props.onSave(
                                type === "percent" ? margin : 100 * calculateMarginFromPrice(props.product.price, margin)
                            )
                        } else {
                            props.onSave(0);
                        }

                        setVisible(false);
                    }}/>
                </div>
            </SwippableModal>
            <div className={classNames(["QuotationMargin-line flex justify-content-center align-items-center"])} style={{opacity: props.locked ? "60%" : "100%"}}>
                <i className="QuotationMargin-line-icon pi pi-reply mr-3 ml-4"
                   style={{"transform": "matrix(1, 0, 0, -1, 0, 0)"}}/>
                <div
                    className="QuotationMargin-line-input flex justify-content-start align-items-center flex-grow-1 bg-white px-3 py-2"
                    onClick={() => !props.locked && setVisible(true)}>
                    <i className="pi pi-percentage mr-2"/>
                    <span>{props.margin ? `${numberWithSpaces(props.margin)} ( ${numberWithSpaces(calculateMarginFromPercent(props.product.price, props.margin / 100) - props.product.price)} € )` : 'Saisir une marge'}</span>
                </div>
            </div>
        </div>
    )
}
