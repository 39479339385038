import React from "react";
import {ProgressSpinner} from "primereact/progressspinner";
import styled from "styled-components";

const WaitingDiv = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3rem 0;
      height: 100%;
      @keyframes p-progress-spinner-color {
        0% {
          stroke: #CE1127;
        }
        100% {
          stroke: #ce1127;
        }
      }
    `;

export type LoadingDivProps = {
    loading?: boolean;
} & React.HTMLProps<HTMLDivElement>;
export const LoadingDiv: React.FC<React.PropsWithChildren<LoadingDivProps>> = ({loading, children,...others}) => {

    return (
        loading ?
            <WaitingDiv>
                <ProgressSpinner />
            </WaitingDiv>
            :
            <div {...others}>
                {children}
            </div>
    )
}
