import * as yup from "yup";

export const OrderShippingSchema = yup.object().shape({
    phone: yup.string().required(),
    name: yup.string().required(),
    reference: yup.string().required(),
    location: yup.string().when('pickup', {
        is: true,
        otherwise: yup.string().required()
    }),
    pickup: yup.boolean()
})
