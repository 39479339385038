import React, {useCallback, useContext, useMemo, useRef} from "react";
import {Button} from "primereact/button";
import {UserBadge} from "@Components/UserBadge";
import {useAuth, useUser} from "@Hooks/firebase";
import './NavigationMenu.scss';
import { signOut } from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {classNames} from "primereact/utils";
import {FirebaseContext} from "@Contexts/FirebaseContext";
import {useApi} from "@Hooks/api";

export type NavigationMenuProps = {
    onAction: () => void;
}
export const NavigationMenu: React.FC<NavigationMenuProps> = props => {

    const user = useUser(true);
    const auth = useAuth();

    const navigate = useNavigate();
    const linkRef = useRef<HTMLAnchorElement>(null);
    const firebaseContext = useContext(FirebaseContext);

    const Api = useApi();

    const onLinkClick = useCallback(() => {
        if (linkRef.current) {
            linkRef.current.click();
        }
    }, [linkRef]);

    const items = useMemo<{label: string, action: () => void, disabled?: boolean, icon?: string}[]>(() => {
        return [{
            label: 'CATALOGUE PRODUIT',
            action: () => {
                navigate('/home');
                props.onAction();
            }
        },
        {
            label: 'MES DEVIS',
            action: () => {
                navigate('/home/my-quotations');
                props.onAction();
            }
        },
        {
            label: 'MES COMMANDES',
            action: () => {
                navigate('/home/my-orders');
                props.onAction();
            }
        }, {
            label: 'MON COMPTE',
            action: () => {
                navigate('/home/profile');
                props.onAction();
            }
        }, {
            label: 'SE DECONNECTER',
            action: () => {
                if (auth) {
                    if (firebaseContext?.token) {
                        Api.user_call_unregisterBrowser({
                            fcmToken: firebaseContext.token
                        }).finally(() => {
                            signOut(auth);
                        })
                    } else
                        signOut(auth);
                }
            }
        }]
    }, [auth, firebaseContext?.token]);


    return (
        <div className="NavigationMenu fixed bottom-0 z-3">
            {user.meta && <UserBadge user={user.meta}/>}
            <hr />
            <div className="NavigationMenu-items flex flex-column">
                {items.map(item => <div className={classNames(["NavigationMenu-items-item", item.disabled && "NavigationMenu-items-item-disabled"])} onClick={() => {
                    if (!item.disabled) {
                        item.action();
                        props.onAction();
                    }
                }} key={item.label}>{item.label} {item.icon && <i className={classNames([item.icon, 'ml-3'])}/>}</div>)}
            </div>
            <hr />
            <Button icon="pi pi-phone" className="w-full mb-3" onClick={onLinkClick}>
                <a href="tel:+33299962011" className="hidden" ref={linkRef}></a>
                <div className="p-button-custom-label ml-3">BESOIN D'AIDE</div>
            </Button>
            <a className="NavigationMenu-tos" href="/assets/CGU.pdf" download>Consulter nos CGU</a>
        </div>
    )
}
