import React, {useCallback, useContext, useEffect, useState} from "react";
import {ScrollTop} from "primereact/scrolltop";
import {Outlet, useNavigate} from "react-router-dom";
import {PageHeaderContext} from "../Contexts/PageHeaderProvider";
import {NavigationMenu} from "@Pages/Home/NavigationMenu";
import {AgencySelector} from "@Pages/Home/AgencySelector";
import {CartContext} from "../Contexts/CartProvider";
import produce from "immer";

export type HomeProps = Record<string, never>
export const Home: React.FC<HomeProps> = () => {

    const [visible, setVisible] = useState<boolean>(false);

    const {setHeader} = useContext(PageHeaderContext);

    const {cart} = useContext(CartContext);

    const navigate = useNavigate();

    const reloadCart = useCallback(() => {
        setHeader(produce(prev => {
            if (prev.right?.icon === 'pi pi-shopping-cart') {
                prev.right = {
                    icon: 'pi pi-shopping-cart',
                    badge: cart.length > 0 ? cart.length.toString() : undefined,
                    action: () => {
                        navigate("/home/cart");
                        setVisible(false);
                    }
                }
            }
            return prev;
        }))
    }, [cart]);

    useEffect(() => {
        setHeader(prev => ({
            right: {
                ...prev.right,
                action: () => {
                    prev.right?.action?.();
                    setVisible(false);
                }
            },
            left: {
                icon: visible ? 'pi pi-times' : 'pi pi-bars',
                action: () => {
                    setVisible(!visible);
                }
            },
        }))
    }, [visible]);

     useEffect(() => {
         reloadCart();
    }, [reloadCart]);

    return (
        <div className="Home h-full overflow-auto relative">
            <ScrollTop threshold={400} className="fixed" target="parent" style={{bottom: "134px", right: "14px"}}/>
            {visible && <NavigationMenu onAction={() => setVisible(false) }/>}
            <AgencySelector/>
            {!visible && <Outlet/>}
        </div>
    )
}
